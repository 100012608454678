export const NOTIFICATION = {
  SHOW: 'notification/show',
  SETUP: 'notification/setup',
}

export const AUTH = {
  LOGIN: 'auth/login',
  LOGIN_FAILED: 'auth/login/failed',
  LOGIN_SUCCESS: 'auth/login/succes',
  LOGOUT: 'auth/logout',
  REGISTER: 'auth/register',
  REGISTER_SUCCESS: 'auth/register/success',

  CHECK_ACCOUNT_EXISTS: 'auth/check/account/exists',
  STORE_ACCOUNT_EXISTS: 'auth/store/account/exists',

  /* OPENID */
  EXCHANGE_CODE_FOR_USERINFO: 'auth/exchange_code_for_userinfo',
  TOKEN_RETRIEVED: 'auth/token/retrieved',
  TOKEN_RETRIEVAL_FAILED: 'auth/token/retrievalFailed',

  RETRIEVE_USERINFO: 'signicat:auth/retrieve/userinfo',
  USERINFO_RETRIEVED: 'auth/userinfo/retrieved',
  USERINFO_RETRIEVAL_FAILED: 'auth/userinfo/retrievalFailed',
}

export const FORMTAKEMENT = {
  SELECTED: 'form/selected',
  ADDED: 'form/added',
  REMOVED: 'form/removed',
  UPDATE_STATUS: 'form/update/status',
  REQUEST_BY_TAKEMENT_ID: 'form/requested/by/takement/id',
  LOADED_FROM_STATE: 'forms/loaded/from/state',

  STEP_SUBMITTED: 'form/step/submit',
  STEP_SUBMITTED_REQUEST: 'form/step/submit/request',
  STEP_SUBMITTED_SUCCESS: 'form/step/submit/success',
  STEP_SUBMITTED_FAILED: 'form/step/submit/failed',

  STEP_STATUS_UPDATED: 'form/step/status/updated',

  REQUEST_FROM_SERVER: 'form/request/from/server',
  REQUEST_FROM_SERVER_SUCCESS: 'form/retrieved/from/server',
  REQUEST_FROM_SERVER_FAILED: 'form/request/from/server/failed',

  DATA_ADDED: 'form/data/added',
  DATA_LOADED: 'form/data/loaded',
}

export const PRESET = {
  BIG: {
    REQUEST_STATUS: 'preset/big/request/big/status',
    REQUEST_STATUS_BY_USERINFO: 'preset/big/request/status/by/userinfo',
    REQUEST_STATUS_SUCCESS: 'preset/big/request/status/success',
    REQUEST_STATUS_FAILED: 'preset/big/request/status/failed',
  },
  IDENTITY: {
    VERIFY: 'preset/identity/verify',
    CREATE_PROCESS: 'signicat:preset/identity/create/process',
    CREATE_PROCESS_SUCCESS: 'preset/identity/create/process/success',
    CREATE_PROCESS_FAILED: 'preset/identity/create/process/failed',

    VERIFY_DOCUMENT: 'preset/identity/verify/document',
    VERIFY_DOCUMENT_SUCCESS: 'preset/identity/verify/document/success',
    VERIFY_DOCUMENT_FAILED: 'preset/identity/verify/document/failed',
  },
}

export const PERSISTANCE = {
  LOAD_STATE: 'storage/loadLocalState',
  LOAD_STATE_COMPLETE: 'storage/loadStateCompleted',
}

export const USERINFO = {
  STORE: 'userinfo/store',
  STORE_IN_REDUX: 'userinfo/store/in/redux',
  PERSIST_TO_LOCALSTORAGE: 'store:userinfo/store/in/localstorage',
}
export const SPINNER = {
  SHOW: 'spinner/show',
  HIDE: 'spinner/hide',
}
