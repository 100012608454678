import { setupNotificationHandler } from "./error.actions"
import { createReducer } from "@reduxjs/toolkit"

const initialState = {
	ref: null
}

const reducer = createReducer(initialState, {
	[setupNotificationHandler]: (state, action) => {
		state.ref = action.payload.ref
	}
})

export default reducer
