import { createReducer } from '@reduxjs/toolkit'
import { stateLoaded } from './victusStorage.actions'

const initialState = {
	loadStateCompleted:false
}

const reducer = createReducer(initialState, {
  [stateLoaded]: (state, action) => {
		state.loadStateCompleted = true
  }
})

export default reducer
