import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'

import formTakementReducer from './form/form.reducer'
import authReducer from './authentication/authentication.reducer'
import userinfoReducer from './userinfo/userinfo.reducer'

import  {formtakementMiddleware} from './form/form.middleware'
import {errorMiddleware} from './error/error.middleware'

import { authMiddleware } from './authentication/authentication.middleware'
import { logMiddleware } from './validation/validation.middleware'
import { userinfoMiddleware } from './userinfo/userinfo.middleware'

import {authStorageMiddleware} from './authentication/authentication.store.middleware'
import formtakementStoreMiddleware from './form/form.store.saga'
import {userinfoStoreMiddleware} from './userinfo/userinfo.store.middleware'

import formtakementStoreSaga from './form/form.saga'
import authSaga from './authentication/authentication.saga'

import victusStorageReducer from './localPersistence/victusStorage.reducer'
import notificationReducer from './error/error.reducer'
/* presets */
import signicatSaga from './preset/signicat.saga'
import {signicatMiddleware} from './preset/signicat.middleware'
const initialiseSagaMiddleware = createSagaMiddleware()

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const combinedReducers = combineReducers({
  form: formTakementReducer,
  auth: authReducer,
  userinfo: userinfoReducer,
  store: victusStorageReducer,
  notification: notificationReducer
})

const store = createStore(
  combinedReducers,
  storeEnhancers(
    applyMiddleware(
      /* logging and errorhandling */
      errorMiddleware,
      logMiddleware,
      /* sync middlewares */
      signicatMiddleware,
      authMiddleware,
      formtakementMiddleware,
      userinfoMiddleware,
      /* storage access */
      authStorageMiddleware,
      userinfoStoreMiddleware,
      /* sagas */
      initialiseSagaMiddleware
    )
  )
)

initialiseSagaMiddleware.run(formtakementStoreMiddleware)
initialiseSagaMiddleware.run(formtakementStoreSaga)
initialiseSagaMiddleware.run(authSaga)
initialiseSagaMiddleware.run(signicatSaga)

export default store
