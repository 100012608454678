export const KNOWN_DATA = {
  FULLNAME: 'fullname',
  NICKNAME: 'nickname',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  BIRTHDATE: 'birthdate',
  INITIALS: 'initials',
  EMAIL: 'email',
  GENDER: 'gender',
  POSTAL_CODE: 'postalcode',
  CITY: 'city',
  STREET: 'street',
  COUNTRY: 'country',
  HOUSENUMBER: 'housenumber',
  HOUSENUMBER_SUFFIX: 'housenumber_suffix',
  LOCALE: 'locale',

  BIG_REGISTRATION_NUMBER: 'BIGRegistrationNumber',
  BIG_CERTIFICATE: 'BIGCertificate',

  SIGNICAT_ASSURE_DOSSIER_ID: 'signicatDossierId',
  SIGNICAT_REFRESH_TOKEN: 'signicatRefreshToken',
  SIGNICAT_ACCESS_TOKEN: 'signicatAccessToken'
}
