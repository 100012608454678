import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { CheckCircleFilled } from '@ant-design/icons'

import { requestFormTakement } from '../../store/form'

import MainContainer from '../../partials/container'
import Footer from '../../partials/footer'
import Menu from '../../partials/menu'

import Hamburger from '../../assets/svg/menu.svg'

import styles from './completedPage.module.scss'

export class FormOverviewSlimPageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      goHome: false,
      goOverview: false,
    }
  }

  handleMenu = () => {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    })
  }

  handleClick = () => {}

  handlePrimaryButton = () => {
    this.setState({
      toNextStep: true,
      goOverview: true,
    })
  }

  handleSecondaryButton = () => {
    this.setState({
      toNextStep: true,
      goOverview: true,
    })
  }

  render() {
    if (this.state.toNextStep) {
      if (this.state.goHome) {
        return (
          <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
        )
      }
      if (this.state.goOverview) {
        return <Redirect to={`/overview`} />
      }
    }

    let checkIcon = (
      <CheckCircleFilled style={{ fontSize: 60, color: 'green' }} />
    )
    let menu = (
      <React.Fragment>
        <img
          className={styles.menuIcon}
          src={Hamburger}
          alt="menu"
          onClick={this.handleMenu}
        />
        <Menu handleMenu={this.handleMenu} visible={this.state.isMenuVisible} />
      </React.Fragment>
    )

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>Complete!</h1>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.checkIcon}>{checkIcon}</div>
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonText="Ga verder"
        primaryButtonAction={this.handlePrimaryButton}
        secondaryButtonText="Overzicht"
        secondaryButtonAction={this.handleSecondaryButton}
      />
    )
    return (
      <MainContainer
        title={title}
        body={body}
        footer={footer}
        optionTopLeft={null}
        optionTopRight={menu}
        center
      />
    )
  }
  componentDidMount() {
    this.props.requestFormTakement(this.props.match.params.formtakementId)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestFormTakement: formtakementId =>
      dispatch(requestFormTakement(formtakementId)),
  }
}
const mapStateToProps = state => {
  return {
    form: state.form.selectedForm,
  }
}
const FormOverviewSlimPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormOverviewSlimPageComponent)
export default withRouter(FormOverviewSlimPage)
