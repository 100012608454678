import React from 'react'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Redirect, withRouter } from 'react-router-dom'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Dropdown from '../../../../partials/dropdown'

import back from '../../../../assets/svg/back.svg'

import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import Privacy from '../../../../partials/privacy'

import styles from './contactSignUploadPage.module.scss'

export class PassportUploadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyVisible: false,
      toNextStep: false,
      goBack: false,
      fileUrl: '',
      goHome: false,
    }
  }
  handlePrimaryButton = () => {
    this.setState({
      toNextStep: true,
    })
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }
  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }
  handleBackButton = () => {
    this.setState({
      goHome: true,
    })
  }

  render() {
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 2}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }
    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )

    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>Contract ondertekenen</h1>
          <p>'Controleer de foto van de ondertekening'</p>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.passportContainer}>
            <img src={this.props.formData.filePreview} alt="dutch passport" />
            <div className={styles.passportOptions}>
              <p onClick={this.handleSecondaryButton}>Nieuwe foto uploaden</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonAction={this.handlePrimaryButton}
        primaryButtonText="Opslaan"
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={!this.state.isVerifying && footer}
        center
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassportUploadPage)

export default withRouter(ConnectedPage)
