import React from 'react'
import { Select } from 'antd'
import { withRouter } from 'react-router-dom'

import CONFIG from '../../../config'
import idin from '../../../assets/svg/idin.svg'
import styles from './bankSelectPage.module.scss'

import Footer from '../../../partials/footer/footer'
import MainContainer from '../../../partials/container'

let redirectToAuth = () => {
  var uuid = require('uuid')
  var id = uuid.v4()

  sessionStorage.setItem('state', id)

  window.location.href = `${CONFIG.backend.base}/signicat/auth?redirectUrl=${CONFIG.frontend.origin}/auth/redirect&state=${id}`
}

function RegisterPage(props) {
  const { Option } = Select
  let handleSelect = () => {}
  let goBack = () => {
    props.history.push("/");
  }

  let title = (
    <React.Fragment>
      <div className={styles.title}>
        <h1>
          Creëer uw veilige <br /> omgeving
        </h1>
      </div>
    </React.Fragment>
  )

  let body = (
    <React.Fragment>
      <div className={styles.bodyContainer}>
        <img src={idin} alt="Idin logo" />
        <p>
          Scan deze code of kies je bank zodat alle <br /> gegevens direct
          ingevuld kunnen worden.{' '}
        </p>
      </div>
      <div className={styles.bankSelect}>
        <label>Bank </label>
        <Select
          className={styles.selectContainer}
          defaultValue="Rabobank"
          onChange={handleSelect}
          size="large"
        >
          <Option value="Rabobank">Rabobank</Option>
          <Option value="Legobank">Legobank</Option>
          <Option value="Ratabank">Ratabank</Option>
        </Select>
      </div>
    </React.Fragment>
  )

  let footer = (
    <React.Fragment>
      <Footer
        primaryButtonAction={redirectToAuth}
        primaryButtonText="Ga verder"
        secondaryButtonText="Terug"
        secondaryButtonAction={goBack}
      />
    </React.Fragment>
  )

  return (
    <MainContainer
      title={title}
      body={body}
      footer={footer}
      center
    ></MainContainer>
  )
}
export default withRouter(RegisterPage)
