import React from 'react'
import { connect } from 'react-redux'
import { requestFromServer } from '../../store/form'
import { Redirect } from 'react-router-dom'

import styles from './formOverviewPage.module.scss'
import MainContainer from '../../partials/container'
import Menu from '../../partials/menu'
import Hamburger from '../../assets/svg/menu.svg'
import Percentage from '../../partials/percentage'
import CardGroup from '../../partials/cardGroup'

export class FormLandingPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      nextStep: null,
      toNextStep: false,
      buttonText: '',
      isFirstTime: true,
      isFormCompleted: true,
    }
  }

  componentDidUpdate() {
    if (this.props.form) {
      let nextStep = this.props.form.steps.find(step => step.status === 0)
      this.setState({
        nextStep: nextStep,
      })
      this.props.form.steps.forEach(step => {
        if (step.status !== 0) {
          this.setState({
            isFirstTime: false,
          })
        }

        if (step.status !== 2) {
          this.setState({
            isFormCompleted: false,
          })
        }
      })

      if (this.state.isFormCompleted) {
        this.setState({
          buttonText: 'Onboarding voltooien!',
        })
      } else if (this.state.isFirstTime) {
        this.setState({
          buttonText: 'Start met toevoegen',
        })
      } else {
        this.setState({
          buttonText: 'Ga verder met toevoegen',
        })
      }
    }
  }
  render() {
    let handleMenu = () => {
      this.setState({ visible: !this.state.visible })
    }

    let handleButton = () => {
      this.setState({
        toNextStep: true,
      })
    }

    if (this.props.form) {
      let menu = (
        <React.Fragment>
          <img
            className={styles.menuIcon}
            src={Hamburger}
            alt="menu"
            onClick={handleMenu}
          />
          <Menu handleMenu={handleMenu} visible={this.state.visible} />
        </React.Fragment>
      )

      let title = (
        <React.Fragment>
          <div className={styles.titleContainer}>
            <h1>Welkom {this.props.userName},</h1>
            <p>Alle gegevens die nog van je nodig zijn op een rijtje.</p>
          </div>
          <Percentage />
        </React.Fragment>
      )

      let footer = (
        <React.Fragment>
          <button
            className="primaryButton"
            onClick={handleButton}
            disabled={!this.state.isFormCompleted && !this.state.nextStep}
          >
            {this.state.buttonText}
          </button>
        </React.Fragment>
      )
      let body = (
        <div className={styles.bodyContainer}>
          {this.props.form.groups.map((group, index) => {
            return (
              <CardGroup
                title={group.title}
                key={index}
                description={group.description}
                steps={this.props.form.steps.filter(
                  s => s.groupId === group.groupId
                )}
                formtakementId={this.props.match.params.formtakementId}
              />
            )
          })}
        </div>
      )
      if (this.state.toNextStep) {
        if (this.state.isFormCompleted) {
          return (
            <Redirect
              to={`${this.props.match.params.formtakementId}/complete`}
            />
          )
        } else {
          return (
            <Redirect
              to={`${this.props.match.params.formtakementId}/${this.state.nextStep.stepId}`}
            />
          )
        }
      }
      return (
        <MainContainer
          optionTopRight={menu}
          title={title}
          body={body}
          footer={footer}
        ></MainContainer>
      )
    } else {
      return <div>Het formulier wordt geladen...</div>
    }
  }

  componentDidMount() {
    this.props.requestForm(this.props.match.params.formtakementId)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestForm: formtakementId => dispatch(requestFromServer(formtakementId)),
  }
}
const mapStateToProps = state => {
  return {
    form: state.form.selectedForm,
    userName: state.userinfo.fullname,
  }
}
const ConnectedFormLandingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormLandingPage)
export default ConnectedFormLandingPage
