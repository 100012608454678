import store from '../../store/store'
export const requireLogin = (to, from, next) => {
  if (store.getState().auth.isLoggedIn) {
    next()
  } else {
    next.redirect({
      pathname: '/',
      state: { originalUrl: to.location.pathname },
    })
  }
}
export const requireLoggedOut = (to, from, next) => {
  if (!store.getState().auth.isLoggedIn) {
    next()
  } else {
    next.redirect({
      pathname: '/',
    })
  }
}
