import React from 'react'
import PinField from 'react-pin-field'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import styles from './loginPage.module.scss'
import MainContainer from '../../../partials/container'

import { login } from '../../../store/authentication'

export class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.pinInputRef = React.createRef()
  }
  state = { redirect: null }
  onPincodeFilledIn(value) {
    this.pinInputRef.current.forEach(input => (input.value = ''))
    this.pinInputRef.current[0].focus()

    this.props.login(value)
  }

  render() {
    if (this.props.isLoggedIn && this.props.loadStateCompleted) {
      return <Redirect to={sessionStorage.getItem('postAuthRedirect') || '/'} />
    }

    let title = (
      <React.Fragment>
        <h1>Inloggen</h1>
        <p>Log in met jouw gekozen pincode</p>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.inputContainer}>
            <PinField
              className={styles.pinInput}
              length={4}
              onComplete={value => this.onPincodeFilledIn(value)}
              style={{
                borderRadius: '3px',
                borderColor: '#BABABA',
              }}
              type="password"
              ref={this.pinInputRef}
            />
          </div>
          {this.props.loginErrorMessage && (
            <div className={styles.errorMessage}>
              Oeps, deze pin code is niet goed! Probeer het opnieuw.
            </div>
          )}
        </div>
      </React.Fragment>
    )

    return <MainContainer title={title} body={body} center></MainContainer>
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    loginErrorMessage: state.auth.loginErrorMessage,
    loadStateCompleted: state.store.loadStateCompleted,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    login: pincode => dispatch(login(pincode)),
  }
}

const ConnectedAuthPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)

export default ConnectedAuthPage
