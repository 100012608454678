import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import { Upload } from 'antd'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Dropdown from '../../../../partials/dropdown'
import Attachment from '../../../../partials/attachment'
import back from '../../../../assets/svg/back.svg'

import styles from './passportSelectPage.module.scss'
import { dropdownOptions } from './data'
import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import ReactLinkify from 'react-linkify'
import Privacy from '../../../../partials/privacy'

export class PassportSelectPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyVisible: false,
      toNextStep: false,
      goBack: false,
      goHome: false,
      fileFormats: props.step.schema.formats
        .map(format => '.' + format)
        .join(','),
    }
    this.handlePrimaryButton = () => {
      this.setState({
        toNextStep: true,
      })
    }

    this.handleSecondaryButton = () => {
      this.setState({
        goBack: true,
      })
    }

    this.handleBackButton = () => {
      this.setState({
        goHome: true,
      })
    }

    this.handlePrivacy = () => {
      this.setState({
        isPrivacyVisible: !this.state.isPrivacyVisible,
      })
    }

    this.handleSelect = (value, type) => {
      this.props.formDataAdded({ [type]: value })
    }

    this.handleFrontUpload = fileUploaded => {
      let fr = new FileReader()
      fr.readAsDataURL(fileUploaded)
      fr.onloadend = () => {
        this.props.formDataAdded({
          [this.props.step.dataBindings.frontImage.value]: fileUploaded
        })
      }
      return false
    }

    this.handleBackUpload = fileUploaded => {
      let fr = new FileReader()
      fr.readAsDataURL(fileUploaded)
      fr.onloadend = () => {
        this.props.formDataAdded({
          [this.props.step.dataBindings.backImage.value]: fileUploaded
        })
      }

      return false
    }
  }

  render() {
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={{
            pathname: `/form/${this.props.match.params.formtakementId}/${
              this.props.match.params.stepId
            }/${this.props.stepNr + 1}`,
          }}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )
    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>{this.props.step.title}</h1>
          <ReactLinkify>
            {this.props.step.schema && this.props.step.schema.description}
          </ReactLinkify>
          <ul>
            {this.props.step.schema &&
              this.props.step.schema.requirements &&
              this.props.step.schema.requirements.map((requirement, index) => {
                return <li key={index}>{requirement}</li>
              })}
          </ul>
        </div>
      </React.Fragment>
    )
    let backImageRequired = () => {
      return this.props.formData[this.props.step.dataBindings.documentType.value] === 'driversLicense'
    }
    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          {dropdownOptions.map((option, index) => {
            if(option.showPreview){
              return (
              <div className={styles.passportContainer}>
                <img src={this.state.fileBackPreview} alt="dutch Identity document" />
                {!this.state.isVerifying && (
                  <div className={styles.passportOptions}>
                    <p onClick={this.handleSecondaryButton}>Nieuwe foto uploaden</p>
                  </div>
                )}
              </div>
              )
            }
            else return (
              <Dropdown
                label={option.label}
                defaultValue={
                  this.props.formData[
                    this.props.step.dataBindings[option.type].value
                  ] || option.defaultValue
                }
                values={option.values}
                type={this.props.step.dataBindings[option.type].value}
                selectCallback={this.handleSelect}
                key={index}
                showSearch={true}
              />
            )
          })}

          {this.props.formData[
            this.props.step.dataBindings.documentNationality.value
          ] &&
            this.props.formData[this.props.step.dataBindings.documentType.value] && (
              <div className={styles.attachment}>
                <Upload
                  name="file"
                  accept={this.state.fileFormats}
                  beforeUpload={this.handleFrontUpload}
                  showUploadList={false}
                >
                  <React.Fragment>
                    <h3>Voorkant</h3>
                    <Attachment type="upload" />
                  </React.Fragment>
                </Upload>
                {backImageRequired() && (
                  <Upload
                    name="file"
                    accept={this.state.fileFormats}
                    beforeUpload={this.handleBackUpload}
                    showUploadList={false}
                  >
                    <React.Fragment>
                      <h3>Achterkant</h3>
                      <Attachment type="upload" />
                    </React.Fragment>
                  </Upload>
                )}
              </div>
            )}
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonText="Ga verder"
        primaryButtonAction={this.handlePrimaryButton}
        primaryButtonDisabled={!this.props.formData[this.props.step.dataBindings.frontImage.value] ||
          (backImageRequired() && !this.props.formData[this.props.step.dataBindings.backImage.value])}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={footer}
        center
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassportSelectPage)

export default withRouter(ConnectedPage)
