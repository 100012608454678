import React from 'react'
import styles from './percentage.module.scss'
import { connect } from 'react-redux'
import percentageBox from '../../assets/svg/percentage.svg'

import { requestFormTakement } from '../../store/form/form.actions'

function PercentageComponent(props) {
  let stepsCompleted = 0
  let percentage = 0
  if (props.form) {
    stepsCompleted = props.form.steps.reduce((accumulator, step) => {
      return accumulator + (step.status === 2 ? 1 : 0)
    }, 0)
    percentage = Math.floor((stepsCompleted * 100) / props.form.steps.length) 
  }
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progress} style={{ width: percentage + "%"}}>
        <div className={styles.progressLabel} >
          <div>
            <img src={percentageBox} alt="Percentage number box" />
            <span>{percentage}%</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    requestFormTakement: formtakementId =>
      dispatch(requestFormTakement(formtakementId)),
  }
}
const mapStateToProps = state => {
  return {
    form: state.form.selectedForm,
  }
}
const Percentage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PercentageComponent)
export default Percentage
