import React from 'react'
import PassportSelectPage from '../passportSelectPage'
import SummaryPage from '../../summary/summaryPage'
import PassportUploadPage from '../passportUploadPage'
import SubmitStep from '../../shared/submitStepPage'
export class Page extends React.Component {
  render() {
    switch (this.props.stepNr) {
      case 1:
        return <SummaryPage step={this.props.step} stepNr={this.props.stepNr} />
      case 2:
        return (
          <PassportSelectPage
            step={this.props.step}
            stepNr={this.props.stepNr}
          />
        )
      case 3:
        return (
          <PassportUploadPage
            step={this.props.step}
            stepNr={this.props.stepNr}
            passport
          />
        )
      case 4:
        return <SubmitStep step={this.props.step}></SubmitStep>
      default:
        return <div>Unknown page?</div>
    }
  }
}
export default Page
