import React from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation, withRouter } from 'react-router-dom'
import './App.css'
import 'antd/dist/antd.css'
import { connect } from 'react-redux'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import { createBrowserHistory } from 'history'

import AuthSwitchPage from './components/authentication/authSwitchPage'
import PostAuthRedirectPage from './components/authentication/postAuthRedirectPage'

/* not logged in */

import CreatePin from './components/authentication/pinSelectPage'
import ConfirmPin from './components/authentication/pinConfirmPage'
import RegisterSuccess from './components/authentication/registerSuccessPage'

import LandingPage from './components/landingPage'

/* logged in */
import OverviewPage from './components/localOnboardingsOverviewPage'
import FormLandingPage from './components/formOverviewPage'
import StepRenderingPage from './components/stepRenderingPage'
import CompletedPage from './components/completedPage'

/* misc */
import { requireLogin } from './components/authentication/authGuard'
import { checkAccountExists } from './store/authentication/authentication.actions'
import DevTestPage from './components/devTestPage'

import FormOverviewSlimPage from './components/formOverviewSlimPage'
import NotificationSystem from 'react-notification-system'
import { setupNotificationHandler } from './store/error/error.actions'

import QRCode from 'qrcode.react'
import { useState } from 'react'

const history = createBrowserHistory()

function DeviceCheck(){    
  let [showMobileWarning, setMobileWarning] = useState(!(navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)))

  if(showMobileWarning){  
    return <div style={{textAlign:'center'}}>
      <p>Deze applicatie is ontworpen voor mobiele apparaten, voor de optimale gebruikservaring raden wij u aan om het proces voort te zetten op uw mobiele apparaat.</p>
      <p>Indien u zeker weet dat u verder wilt gaat op dit apparaat, klik dan op de onderstaande knop</p>
      <button onClick={()=>{setMobileWarning(false)}}>Verdergaan op dit apparaat</button>
      <p>Indien u het proces verder wilt voortzetten op uw mobiele apparaat, scan dan deze qr code met een qr code scanner app om direct verder te gaan waar u gebleven bent, of open dezelfde link op uw mobiele apparaat.</p>
      <QRCode style={{width:'80%', maxWidth:'250px', height: 'auto'}} value={window.location.href}></QRCode>
    </div>
  }
  else{
    return <Switch>
          <Route path="/auth" exact component={AuthSwitchPage} />
          <Route path="/auth/redirect" exact component={PostAuthRedirectPage} />
          <Route path="/auth/register/pincode/confirm" component={ConfirmPin} />
          <Route path="/auth/register/pincode" component={CreatePin} />
          <Route path="/auth/register/success" component={RegisterSuccess} />
          <Route path="/" exact component={LandingPage} />
          <GuardProvider guards={[requireLogin]}>
            <GuardedRoute path="/dev" component={DevTestPage} />
            <GuardedRoute
              path="/form/step/completed/:formtakementId/:stepId"
              exact
              component={FormOverviewSlimPage}
            />
            <GuardedRoute
              path="/form/:formtakementId/complete"
              exact
              component={CompletedPage}
            />
            <GuardedRoute
              path="/form/:formtakementId/:stepId/:stepNr"
              exact
              component={StepRenderingPage}
            />
            <GuardedRoute
              path="/form/:formtakementId/:stepId"
              exact
              component={StepRenderingPage}
            />
            <GuardedRoute
              path="/form/:formtakementId"
              exact
              component={FormLandingPage}
            />

            <GuardedRoute path="/overview" exact component={OverviewPage} />
          </GuardProvider>
        </Switch>
  }
}

export class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.notificationSystem = React.createRef()
  }
  componentDidMount() {
    this.props.checkAccountExists()
    this.props.setupNotificationHandler(this.notificationSystem)
  }
  render() {
    return (
      <Router history={history}>
        <NotificationSystem ref={this.notificationSystem} />
        <DeviceCheck></DeviceCheck>      
      </Router>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkAccountExists: () => dispatch(checkAccountExists()),
    setupNotificationHandler: ref => dispatch(setupNotificationHandler(ref)),
  }
}

export const App = connect(null, mapDispatchToProps)(AppComponent)
export default App
