import { NOTIFICATION } from "../action-types"
import { showError } from "./error.actions"

export function errorMiddleware({getState,dispatch}) {
  return function (next) {
		return function (action) {
			if(action.type === showError.type){
				let ref = getState().notification.ref
				if(ref){
					const notification = ref.current
					if(typeof action.payload.message === 'string')
						notification.addNotification(action.payload)
					else console.error(action.payload)
				}
			}
		  return next(action)
    }
  }
}
