import { takeEvery, call, put } from 'redux-saga/effects'

import CONFIG from '../../config'
import {
  retrievedFromServer,
  requestFromServerFailed,
  submitStepSuccess,
  requestFromServer,
  submitStep,
  stepStatusUpdated,
  requestBigStatusByUserinfo,
  requestBigStatusFailed,
  requestBigStatusSuccess,
  submitStepRequest,
} from './form.actions'
import { showError } from '../error/error.actions'

export default function* watcherSaga() {
  yield takeEvery(requestFromServer.type, getFormWorkerSaga)
  yield takeEvery(submitStepRequest.type, submitFormStepSaga)
  yield takeEvery(stepStatusUpdated.type, stepStatusUpdatedSaga)
}



function* stepStatusUpdatedSaga(action) {
  try {
    yield call(updateStepStatus, action.payload)
  } catch (e) {
    yield put(showError(e))
  }
}

function updateStepStatus(payload) {
  const { statusMessage, status, formId, stepId } = payload

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
  }

  let query = new URLSearchParams({ status })
  if (statusMessage) query.set('statusMessage', statusMessage)
  return fetch(
    `${CONFIG.backend.base}/victus/f/${encodeURIComponent(
      formId
    )}/${encodeURIComponent(stepId)}?${query.toString()}`,
    requestOptions
  )
}

function* submitFormStepSaga(action) {
  const {formId, stepId} = action.payload
  try {
    const payload = yield call(submitFormStep, action.payload)
    yield put(submitStepSuccess(formId, stepId, payload))
  } catch (e) {
    yield put(
      submitStepSuccess(formId, stepId, {
        status: 3,
        statusMessage: 'Er is iets fout gegaan...',
      })
    )
    yield put(showError(e))
  }
}

function submitFormStep(payload) {
  const { formData, formId, stepId } = payload
  var requestOptions = {
    method: 'POST',
    body: formData,
    redirect: 'follow',
  }

  for(let [key,val] of formData.entries()){
    console.log('lets gooo',key,val)
  }

  return fetch(
    `${CONFIG.backend.base}/victus/submit?formId=${encodeURIComponent(
      formId
    )}&stepId=${encodeURIComponent(stepId)}`,
    requestOptions
  ).then(async response => {
    let result = await response.json()
    switch (response.status) {
      case 200:
        return result
      case 400:
        if (result.status) {
          return result
        }
        break
      default:
        break
    }
    throw new Error(result)
  })
}

function* getFormWorkerSaga(action) {
  try {
    const { formId } = action.payload
    const payload = yield call(getForm, formId)
    yield put(retrievedFromServer(payload))
  } catch (e) {
    yield put(requestFromServerFailed(action.payload, e))
  }
}
function getForm(formId) {

  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  }

  let req = fetch(
    `${CONFIG.backend.base}/victus/f/${encodeURIComponent(formId)}`,
    requestOptions
  )
  return req
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw new Error(response.json())
      }
    })
    .catch(error => {
      console.error(
        `An error occured while receiving the form with id '${formId}' from the server`,
        error
      )
      throw error
    })
}
