import React from 'react'
import { Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { exchangeCodeForUserInfo } from '../../../store/authentication'

export class PostAuthenticationRedirect extends React.Component {
  constructor(props) {
    super(props)
    let s = new URLSearchParams(this.props.location.search)
    this.state = {
      code: s.get('code'),
      state: s.get('state'),
      sessionState: s.get('session_state'),
      stateMatches: s.get('state') === sessionStorage.getItem('state'),
      redirect: null,
    }
  }
  render() {
    const exchangeCodeAndContinue = () => {
      this.props.exchangeCodeForUserdata(this.state.code)
      this.setState({ redirect: '/auth/register/pincode' })
    }
    const goToFleksWorksDomain = () => {
      this.props.location.replace(
        'https://demo1.datastreams.refleks.tech' +
          this.props.location.pathname +
          this.props.location.search
      )
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}></Redirect>
    } else
      return (
        <div>
          <p>click here to retrieve userinfo, and continue registering</p>
          <button onClick={exchangeCodeAndContinue}>
            continue registering
          </button>
          <button onClick={goToFleksWorksDomain}>
            Continue the registering on the fleks works website!
          </button>
        </div>
      )
  }

  componentDidMount() {
    if (!window.location.host.startsWith('victus.technologylab.info')) {
      this.props.exchangeCodeForUserdata(this.state.code)
      this.setState({ redirect: '/auth/register/pincode' })
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    exchangeCodeForUserdata: code => dispatch(exchangeCodeForUserInfo(code)),
  }
}

const PostAuthRedirectConnected = connect(
  null,
  mapDispatchToProps
)(PostAuthenticationRedirect)

export default PostAuthRedirectConnected
