import { addFormTakement, formSelected, requestFromServer, submitStep, submitStepRequest, submitStepFailed, submitStepSuccess, requestFromServerFailed, retrievedFromServer,requestFormTakement, formDataAdded, stepStatusUpdated, updateFormStatus, requestBigStatusSuccess, requestBigStatusFailed, requestBigStatus, requestBigStatusByUserinfo } from './form.actions'
import { showError } from '../error/error.actions'
import { KNOWN_DATA } from '../userinfo/userinfo.constants'
import JSZip from 'jszip'

async function createFormData(formdata) {
  let result = new FormData()
  for(const [key,val] of formdata){
    console.log(key, val)
    let value
    if(val instanceof Array && val.length > 0 && val[0] instanceof File){
        console.log('its a file!')
      let zip = new JSZip()
      for(let file of val){
        zip.file(file.name,file)
      }
      value = await zip.generateAsync({type:"blob"})
      .then((content) => {
          console.log('content is: ',content)
          return content
      })
      value = new File([value], `${key}.zip`)
    }
    else value = val
    result.append(key,value)
  }
  console.log('returning fd')
  return result
}

export function formtakementMiddleware({ getState, dispatch }) {
  return function (next) {
    return function (action) {
      if (action.type === requestFormTakement.type) {
        const {formId} = action.payload

        let formsWithId = getState().form.forms.filter(o => o._id === formId)
        if (formsWithId.length > 0) {
          return dispatch(formSelected(formsWithId[0]._id))
        }
        return dispatch(requestFromServer(formId))
      }

      if (action.type === retrievedFromServer.type) {
        dispatch(addFormTakement(action.payload))
        return dispatch(formSelected(action.payload._id))
      } 
      
      if (action.type === requestFromServerFailed.type) {
        const {formId, error} = action.payload
        let formsWithId = getState().form.forms.filter(o => o._id === formId)
        if (formsWithId.length > 0) {
          if(formsWithId.length > 1){
            console.debug(`${formsWithId.length} forms found locally for id ${action.payload.formtakementId}, which is more than the expected 1. taking first form...`)
          }

          return dispatch(formSelected(formsWithId[0]._id))
        }

        return dispatch(showError('form retrieval failed', {data: error}))
      }

      if(action.type === submitStep.type){
        const { stepId } = action.payload

        if(!stepId){
          return dispatch(submitStepFailed(stepId, "No stepId was supplied while submitting the step!"))
        }
        let form = getState().form.selectedForm
        if(!form){
          return dispatch(submitStepFailed(stepId, "Tried to submit a step while no form is currently being edited!"))
        }

        let stepsWithId = form.steps.filter(s => s.stepId === stepId)
        if(stepsWithId.length === 0){
          return dispatch(submitStepFailed(stepId, `The form '${form._id} (${form.title})' does not contain a step with id '${stepId}'`))
        }
        new Promise(async (resolve,reject) => {
          const vals = Object.values(stepsWithId[0].dataBindings).map(db=>db.value)

          let filteredStoreDataByStep = Object.entries(getState().form.selectedFormData).filter(([key]) => vals.includes(key))
          let fd = await createFormData(filteredStoreDataByStep)
          dispatch(submitStepRequest(stepId,form._id,fd))
          resolve()
        })

      }
      
      if(action.type === submitStepSuccess.type){
        const {stepId, formId, result} = action.payload
        console.debug(`STORE: submit step '${stepId}' on form '${formId}' successful`, `RESPONSE: "${result}"`)
        
        let forms = getState().form.forms.filter(f => f._id === formId)
        if(forms.length === 0){
          return dispatch(showError(`No form could be found with id '${formId}'`))
        }
        console.log('statusmessage here:', result.statusMessage, result)
        dispatch(updateFormStatus(formId, stepId, result.status, result.statusMessage))
        // dispatch(stepStatusUpdated(formId,stepId, result.status, result.statusMessage))
      }

      if(action.type === submitStepFailed.type){
        const { stepId, errorMessage } = action.payload
        const mess = `FORM: an error occured while submitting step '${stepId}': ${errorMessage}`
        return dispatch(showError(mess))
      }

      if (action.type === formDataAdded.type) {
        let selectedForm = getState().form.selectedForm
        if(selectedForm == null){
          return dispatch(showError(`there is no form selected yet!`))
        }
        else{
          action.payload.formtakementId = selectedForm._id
        }
      }

      if(action.type === requestBigStatus.type){
        let ui = getState().userinfo
        //This line is the right one, other one is just a test until Signicat contract is finished
        // return dispatch(requestBigStatusByUserinfo(ui[KNOWN_DATA.INITIALS], ui[KNOWN_DATA.LASTNAME], ui[KNOWN_DATA.BIRTHDATE], ui[KNOWN_DATA.GENDER]))
        return dispatch(requestBigStatusByUserinfo(
          'M.E.', 
          'Bekema', 
          null, 
          1
        ))
      }

      if(action.type === requestBigStatusSuccess.type){
        const {bigRegistrationNumber, isValid } = action.payload
        dispatch(formDataAdded({[KNOWN_DATA.BIG_REGISTRATION_NUMBER]: bigRegistrationNumber}))
      }
      if(action.type === requestBigStatusFailed.type){
        dispatch(showError(action.payload.errorMessage))
      }
      return next(action)
    }
  }
}
