import React, { useRef } from 'react'
import PinField from 'react-pin-field'

import styles from './pinSelectPage.module.scss'

import MainContainer from '../../../partials/container'

function CreatePin(props) {
  let goToConfirmPin = () => {
    props.history.push('/auth/register/pincode/confirm')
  }
  let onComplete = value => {
    goToConfirmPin()
  }
  let title = (
    <React.Fragment>
      <h1>Kies uw veilige inlog</h1>
      <p>Kies jouw persoonlijke pincode</p>
    </React.Fragment>
  )

  let body = (
    <React.Fragment>
      <div className={styles.bodyContainer}>
        <div className={styles.inputContainer}>
          <PinField
            className={styles.pinInput}
            length={4}
            onComplete={value => onComplete(value)}
            style={{
              borderRadius: '3px',
              borderColor: '#BABABA',
            }}
            type="password"
          />
        </div>
      </div>
    </React.Fragment>
  )

  return <MainContainer title={title} body={body} center></MainContainer>
}

export default CreatePin
