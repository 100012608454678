import { PRESET } from '../action-types'
import { createAction } from '@reduxjs/toolkit'

export const verifyDocument = createAction(
  PRESET.IDENTITY.VERIFY,
  (formId, stepId) => {
    return {
      payload: {
        formId,
        stepId,
      },
    }
  }
)

export const createIdentityVerificationProcess = createAction(
  PRESET.IDENTITY.CREATE_PROCESS,
  (dossierId, documentType, documentNationality, formId, stepId, lastname) => {
    return {
      payload: {
        dossierId,
        documentType,
        documentNationality,
        formId,
        stepId,
        lastname,
      },
    }
  }
)

export const createIdentityVerificationProcessSuccess = createAction(
  PRESET.IDENTITY.CREATE_PROCESS_SUCCESS,
  (result, formId, stepId) => {
    return {
      payload: {
        dossierId: result.dossierId,
        processId: result.processId,
        formId,
        stepId,
      },
    }
  }
)

export const createIdentityVerificationProcessFailed = createAction(
  PRESET.IDENTITY.CREATE_PROCESS_FAILED,
  error => {
    return {
      payload: {
        error,
      },
    }
  }
)

export const verifyIdentityDocument = createAction(
  PRESET.IDENTITY.VERIFY_DOCUMENT,
  (dossierId, processId, frontImage, backImage, selfie) => {
    return {
      payload: {
        dossierId,
        processId,
        frontImage,
        backImage,
        selfie,
      },
    }
  }
)

export const verifyIdentityDocumentSuccess = createAction(
  PRESET.IDENTITY.VERIFY_DOCUMENT_SUCCESS,
  () => {
    return {
      payload: {},
    }
  }
)

export const verifyIdentityDocumentFailed = createAction(
  PRESET.IDENTITY.VERIFY_DOCUMENT_FAILED,
  error => {
    return {
      payload: {
        error,
      },
    }
  }
)
