import React from 'react'
import styles from './registersuccessPage.module.scss'

import { storeData } from '../../../store/userinfo'

import { CheckCircleFilled } from '@ant-design/icons'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import MainContainer from '../../../partials/container'
import Footer from '../../../partials/footer'

export class RegisterSuccess extends React.Component {
  state = { doRedirect: false }
  constructor(props) {
    super(props)
    this.userInfo = {
      naam: this.props.userInfo.fullname,
      geboortedatum: `${this.props.userInfo.birthdate.substr(
        4,
        2
      )}-${this.props.userInfo.birthdate.substr(
        6,
        2
      )}-${this.props.userInfo.birthdate.substr(0, 4)}`,
      geslacht: this.props.userInfo.gender === 1 ? 'Vrouw' : 'Man',
      straat:
        this.props.userInfo.street +
        ' ' +
        this.props.userInfo.housenumber +
        (this.props.userInfo.housenumber_suffix? this.props.userInfo.housenumber_suffix:''),
      postcode: this.props.userInfo.postalcode,
      woonplants: this.props.userInfo.city,
      email: this.props.userInfo.email,
    }
  }
  render() {
    if (this.state.doRedirect) {
      return <Redirect to={sessionStorage.getItem('postAuthRedirect') || '/'} />
    }

    let title = (
      <React.Fragment>
        <h1>Het is gelukt</h1>
        <div className={styles.iconContainer}>
          <CheckCircleFilled
            style={{
              fontSize: 32,
              color: '#80BB00',
            }}
          />
        </div>
        <p>Uw profiel is aangemaakt</p>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          {Object.keys(this.userInfo).map((key, index) => {
            return (
              <div key={index} className={styles.infoField}>
                <h3>{key}</h3>
                <p>{this.userInfo[key]}</p>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )

    let footer = (
      <React.Fragment>
        <Footer
          primaryButtonAction={() => {
            this.setState({ doRedirect: true })
          }}
          primaryButtonText="Ga verder"
          secondaryButtonText="Terug"
        />
      </React.Fragment>
    )
    return (
      <MainContainer
        title={title}
        body={body}
        footer={footer}
        center
      ></MainContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userinfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeData: user => dispatch(storeData(storeData(user))),
  }
}

const ConnectedRegisterSuccess = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSuccess)

export default ConnectedRegisterSuccess
