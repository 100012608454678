import React from 'react'

import { Input } from 'antd'
import styles from './input.module.scss'

function InputNumber(props) {
  let handleChange = e => {
    if (e.target.value.length >= 0) {
      props.handleInput(e.target.value)
    } else{
        props.handleInput(null);
    }
  }
  return (
    <React.Fragment>
      <label>{props.label}</label>
      <Input
        className={styles.inputContainer}
        onChange={handleChange}
        size="large"
        disabled={props.disabled}
        placeholder={props.placeholder}
        defaultValue = {props.defaultValue}
        value = {props.value}
        type="text"
      ></Input>
    </React.Fragment>
  )
}
export default InputNumber
