import { createAction } from "@reduxjs/toolkit";
import { NOTIFICATION } from "../action-types";

export const showError = createAction(NOTIFICATION.SHOW, (message, options = {data:null,seconds:3, title:null}) =>{
	return {
		payload:{
			message,
			autoDismiss: options?.seconds || 3,
			title: options?.title,
			position: 'tr',
			level: 'error'
		}
	}
}) 

export const showSuccess = createAction(NOTIFICATION.SHOW, (message, options = {data:null,seconds:3, title:null}) => {
	return {
		payload:{
			message,
			autoDismiss: options?.seconds || 3,
			title: options?.title,
			position: 'tr',
			level: 'success'
		}
	}
}) 

export const showInfo = createAction(NOTIFICATION.SHOW, (message, options = {data:null,seconds:3, title:null}) =>{
	return {
		payload:{
			message,
			autoDismiss: options?.seconds || 3,
			title: options?.title,
			position: 'tr',
			level: 'info'
		}
	}
}) 

export const showWarning = createAction(NOTIFICATION.SHOW, (message, options = {data:null,seconds:3, title:null}) =>{
	return {
		payload:{
			message,
			autoDismiss: options?.seconds || 3,
			title: options?.title,
			position: 'tr',
			level: 'warning'
		}
	}
}) 


export const setupNotificationHandler = createAction(NOTIFICATION.SETUP, (ref) =>{
	return {
		payload:{
			ref
		}
	}
}) 