import React from 'react'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Redirect, withRouter } from 'react-router-dom'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Dropdown from '../../../../partials/dropdown'

import back from '../../../../assets/svg/back.svg'

import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import Privacy from '../../../../partials/privacy'

import styles from './passportUploadPage.module.scss'
import {
  createIdentityVerificationProcess,
  verifyIdentityDocument,
  verifyDocument,
} from '../../../../store/preset/preset.actions'
import { dropdownOptions } from '../passportSelectPage/data'
export class PassportUploadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyVisible: false,
      isVerifying: false,
      toNextStep: false,
      goBack: false,
      fileUrl: '',
      goHome: false,
      filePreview:null
    }
  }
  handlePrimaryButton = () => {
    this.setState({
      toNextStep: true,
    })
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }
  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }
  handleBackButton = () => {
    this.setState({
      goHome: true,
    })
  }

  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 64, color: 'black' }} spin />
    )

    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }
    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let handleVerification = () => {
      this.props.verifyDocument(
        this.props.match.params.formtakementId,
        this.props.match.params.stepId
      )
      this.handlePrimaryButton()
    }

    // let dropdownOptions
    // if (this.props.passport) {
    //   dropdownOptions = [
    //     {
    //       label: 'Soort legitimatiebewijs',
          
    
    //     },
    //     {
    //       label: 'Land van uitgaven',
    //       defaultValue: this.props.formData[
    //         this.props.step.dataBindings.documentNationality.value
    //       ],
    //     },
    //   ]
    // }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )
    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>Identificatie</h1>
          <p>
            {!this.state.isVerifying
              ? 'Controleer de foto van het legitimatiebewijs.'
              : 'Een moment terwijl we het identificatiebewijs verifiëren.'}
          </p>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          {this.props.passport && !this.state.isVerifying ? (
            dropdownOptions.map((option, index) => (
              <Dropdown
              label={option.label}
              defaultValue={
                this.props.formData[
                  this.props.step.dataBindings[option.type].value
                ] || option.defaultValue
              }
              values={option.values}
              type={this.props.step.dataBindings[option.type].value}
                disabled
                key={index}
              />
            ))
          ) : (
            <div className={styles.spinner}>
              <Spin indicator={antIcon} />
            </div>
          )}

          {this.state.fileFrontPreview && <div className={styles.passportContainer}>
            <h2>Voorkant</h2>
            <img src={this.state.fileFrontPreview} alt="dutch Identity document" />
            {!this.state.isVerifying && (
              <div className={styles.passportOptions}>
                <p onClick={this.handleSecondaryButton}>Nieuwe foto uploaden</p>
              </div>
            )}
          </div>}

          {this.state.fileBackPreview && <div className={styles.passportContainer}>
            <h2>Achterkant</h2>
            <img src={this.state.fileBackPreview} alt="dutch Identity document" />
            {!this.state.isVerifying && (
              <div className={styles.passportOptions}>
                <p onClick={this.handleSecondaryButton}>Nieuwe foto uploaden</p>
              </div>
            )}
          </div>}
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonAction={handleVerification}
        primaryButtonText="Verifiëren"
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={!this.state.isVerifying && footer}
        center
      />
    )
  }
  componentDidMount(){
    let frontImage = this.props.formData[this.props.step.dataBindings.frontImage.value]
    if(frontImage){
      let fr = new FileReader(frontImage)
      fr.readAsDataURL(this.props.formData[this.props.step.dataBindings.frontImage.value])
      fr.onloadend = () => {
        this.setState({fileFrontPreview:fr.result})
      }
    }

    let backImageRequired = () => {
      return this.props.formData[this.props.step.dataBindings.documentType.value] === 'driversLicense'
    }
    let backImage = this.props.formData[this.props.step.dataBindings.backImage.value]
    if(backImageRequired() && backImage){
      let fr = new FileReader(backImage)
      fr.readAsDataURL(this.props.formData[this.props.step.dataBindings.backImage.value])
      fr.onloadend = () => {
        this.setState({fileBackPreview:fr.result})
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
    verifyDocument: (formId, stepId) =>
      dispatch(verifyDocument(formId, stepId)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassportUploadPage)

export default withRouter(ConnectedPage)
