import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
 

import { Upload } from 'antd'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Attachment from '../../../../partials/attachment'
import back from '../../../../assets/svg/back.svg'

import styles from './contractSignMethodChoicePage.module.scss'
import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import Privacy from '../../../../partials/privacy'

export class ContractSignMethodChoicePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toNextStep: false,
      goBack: false,
      isPrivacyVisible: false,
      goHome: false,
      toUpload: false,
      toSign: false,
    }
    this.handlePrimaryButton = upload => {
      let toUpload = upload === true? true : false
      this.setState({
        toNextStep: true,
        toUpload: toUpload,
      })
    }

    this.handleSecondaryButton = () => {
      this.setState({
        goBack: true,
      })
    }

    this.handleBackButton = () => {
      this.setState({
        goHome: true,
      })
    }

    this.handlePrivacy = () => {
      this.setState({
        isPrivacyVisible: !this.state.isPrivacyVisible,
      })
    }

    this.handleBeforeUpload = fileUploaded => {
      let fr = new FileReader()
      fr.readAsDataURL(fileUploaded)
      fr.onloadend = () => {
        this.props.formDataAdded({ file: fileUploaded, filePreview: fr.result })
      }
      this.handlePrimaryButton(true)
      return false
    }
  }
   render() {
    if (this.state.toNextStep === true) {
      let nextStepNr = this.state.toUpload ? 1 : 2
      return (
        <Redirect
          to={{
            pathname: `/form/${this.props.match.params.formtakementId}/${
              this.props.match.params.stepId
            }/${this.props.stepNr + nextStepNr}`,
          }}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )

    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>{this.props.step.title}</h1>

          {this.props.step.schema && this.props.step.schema.description}

          <ul>
            {this.props.step.schema &&
              this.props.step.schema.requirements &&
              this.props.step.schema.requirements.map((requirement, index) => {
                return <li key={index}>{requirement}</li>
              })}
          </ul>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.attachment}>
            <Upload
              name="file"
              beforeUpload={this.handleBeforeUpload}
              showUploadList={false}
            >
              <React.Fragment>
                <Attachment type="upload" />
              </React.Fragment>
            </Upload>
          </div>
          <div
            className={styles.attachment}
            onClick={this.handlePrimaryButton}
          >
            <Attachment type="signature" />
          </div>
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
     return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={footer}
        center
      />
     )
   }
 }

const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractSignMethodChoicePage)

export default withRouter(ConnectedPage)