import React from 'react'
import KvkLookupPage from '../kvkLookupPage'
import SummaryPage from '../../summary/summaryPage'
import SubmitStep from '../../shared/submitStepPage'
export class Page extends React.Component {
  render() {
		switch(this.props.stepNr){
			case 1:
				return <SummaryPage step={this.props.step} stepNr={this.props.stepNr}/>
			case 2: 
				return <KvkLookupPage step={this.props.step} stepNr={this.props.stepNr} />
			case 3: 
				return <SubmitStep step={this.props.step}></SubmitStep>
			default: 
			return <div>Unknown page?</div>
		}
  }
}

export default Page

