import React, { useState } from 'react'
import { Drawer } from 'antd'

import support from '../../assets/svg/support.svg'
import styles from './privacy.module.scss'

function Privacy(props) {
  const [isPrivacyVisible, setIsPrivacyVisible] = useState(false);
  let handleClose = () => {
    setIsPrivacyVisible(!isPrivacyVisible)
  }

  let handlePrivacy = () => {
    setIsPrivacyVisible(true)
  }
  let privacyContent = [
    {
      subtitle: 'Wat slaan we op?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas lacinia dictum.',
    },
    {
      subtitle: 'Wie beheert het?',
      description:
        'Vivamus vehicula tempus elit at feugiat. Cras id neque ipsum. Nulla sit amet semper nibh.',
    },
    {
      subtitle: 'Wat is het bewaartermijn?',
      description:
        'Maecenas ullamcorper, mi nec posuere vehicula, ante tellus efficitur nulla, sit amet ultricies ligula quam eget nulla.',
    },
  ]
  return (
    <React.Fragment>
      <img src={support} alt="Privacy drawer" onClick={handlePrivacy} />
      <Drawer
        title="Privacy beheren"
        closable={true}
        placement="bottom"
        onClose={handleClose}
        visible={isPrivacyVisible}
        height={400}
      >
        <div className={styles.privacyContainer}>
          {privacyContent.map((content, index) => {
            return (
              <div key={index}>
                <h3>{content.subtitle}</h3>
                <p>{content.description}</p>
              </div>
            )
          })}
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default Privacy
