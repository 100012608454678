import React from 'react'

import styles from './footer.module.scss'

function Footer(props) {
  return (
    <React.Fragment>
      <div className={styles.footerContainer}>
        {props.secondaryButtonText && (
          <button
            onClick={props.secondaryButtonAction}
            className={styles.secondary}
          >
            {props.secondaryButtonText}
          </button>
        )}
        {props.primaryButtonText && (
          <button
            onClick={props.primaryButtonAction}
            disabled={props.primaryButtonDisabled}
            className={styles.primary}
          >
            {props.primaryButtonText}
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

export default Footer
