import { victusVersion, encrypt, createDBConnection, decrypt } from '../localPersistence/storageHelper'
import { TABLES, MODES } from '../localPersistence/victusStorage.constants'
import {storeAccountExists, loginSuccess, loginFailed, checkAccountExists, login, registerSuccess} from './authentication.actions'
import { loadState } from '../localPersistence/victusStorage.actions'
import { storeData } from '../userinfo/userinfo.actions'

export function authStorageMiddleware({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      if(action.type === login.type){
        //TODO: do check if login succes
        createDBConnection(db=>{
          let request = db.transaction([TABLES.METADATA]).objectStore(TABLES.METADATA).get('version')
          request.onsuccess = async e => {
            let versionFromStore = await decrypt(e.target.result, action.payload)
            if(versionFromStore === victusVersion){
              dispatch(loginSuccess(action.payload))
              dispatch(loadState())
            }
            else{
              dispatch(loginFailed("De ingevulde pincode was onjuist"))
            }
          }

        })
      }
     if (action.type === registerSuccess.type) {
        createDBConnection(async db => {
          let encrypted = await encrypt(
            'version',
            victusVersion,
            action.payload
          )

          let request = db.transaction([TABLES.METADATA], MODES.RW)
            .objectStore(TABLES.METADATA)
            .put(encrypted)
          request.onsuccess = () => {
            dispatch(storeData(getState().userinfo))
            dispatch(checkAccountExists())
          }
        })
      }
     if (action.type === checkAccountExists.type) {
        createDBConnection(db => {
          let tx = db.transaction([TABLES.METADATA], MODES.RW)
          let store = tx.objectStore(TABLES.METADATA)
          let request = store.get('version')
          request.onsuccess = e => {
            let result = e.target.result
            if (result) {
              dispatch(storeAccountExists(true))
            } else {
              dispatch(storeAccountExists(false))
            }
          }
          request.onerror = e =>
            console.error('STORE: while receiving version from store', e)
        })
      }
      return next(action)
    }
  }
}
