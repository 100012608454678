import {
  createIdentityVerificationProcessFailed,
  createIdentityVerificationProcessSuccess,
  verifyIdentityDocument,
  verifyIdentityDocumentFailed,
  createIdentityVerificationProcess,
  verifyDocument,
} from './preset.actions'
import { CUSTOM_PROPS } from './index'
import { showError } from '../error/error.actions'
import { storeData } from '../userinfo/userinfo.actions'
import { formDataAdded } from '../form/form.actions'
import { KNOWN_DATA } from '../userinfo/userinfo.constants'
export function signicatMiddleware({ getState, dispatch }) {
  return function (next) {
    return function (action) {
      if (action.type.startsWith('signicat:')) {
        let token = getState().userinfo[KNOWN_DATA.SIGNICAT_ACCESS_TOKEN]
        console.debug('AUTH: added signicat access token to request payload')
        if (!token) {
          return dispatch(
            showError('The authentication token for signicat is missing')
          )
        }
        if (!action.payload) action.payload = {}
        action.payload.accessToken = token
      }

      if (action.type === verifyDocument.type) {
        const { formId, stepId } = action.payload
        let form = getState().form.selectedForm
        if (form._id !== formId) {
          return dispatch(
            showError(
              `The formId ${form._id} from the current form doesn't match the requested id ${formId}`
            )
          )
        }
        let steps = form.steps.filter(s => s.stepId === stepId)
        if (steps.length === 0)
          return dispatch(
            showError(
              `No step found with id '${stepId}' on the form '${formId}'`
            )
          )
        let {
          documentType,
          documentNationality,
        } = steps[0].dataBindings
        let fd = getState().form.selectedFormData

        return dispatch(
          createIdentityVerificationProcess(
            getState().userinfo[CUSTOM_PROPS.DOSSIER_ID],
            fd[documentType.value],
            fd[documentNationality.value],
            formId,
            stepId,
            getState().userinfo[KNOWN_DATA.LASTNAME]
          )
        )
      }

      if (action.type === createIdentityVerificationProcessSuccess.type) {
        const { dossierId, processId, formId, stepId } = action.payload
        dispatch(
          storeData({
            [CUSTOM_PROPS.DOSSIER_ID]: dossierId,
          })
        )
        dispatch(
          formDataAdded({
            [CUSTOM_PROPS.PROCESS_ID]: processId,
          })
        )

        let form = getState().form.selectedForm
        if (form._id !== formId) {
          return dispatch(
            showError(
              `The formId ${form._id} from the current form doesn't match the requested id ${formId}`
            )
          )
        }
        let steps = form.steps.filter(s => s.stepId === stepId)
        if (steps.length === 0)
          return dispatch(
            showError(
              `No step found with id '${stepId}' on the form '${formId}'`
            )
          )
        let { frontImage, backImage, selfie } = steps[0].dataBindings
        let fd = getState().form.selectedFormData
        dispatch(
          verifyIdentityDocument(
            dossierId,
            processId,
            fd[frontImage.value],
            fd[backImage.value],
            fd[selfie.value]
          )
        )
      }

      if (action.type === createIdentityVerificationProcessFailed.type) {
        return dispatch(showError(action.payload.error?.message))
      }

      if (action.type === createIdentityVerificationProcess.type) {
        let d = action.payload
        d.dossierId = getState().userinfo[CUSTOM_PROPS.DOSSIER_ID]
        // d.lastname = getState().userinfo[KNOWN_DATA.LASTNAME]
      }

      return next(action)
    }
  }
}
