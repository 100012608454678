import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { CheckCircleFilled } from '@ant-design/icons'
import styles from './card.module.scss'

import Edit from '../../../assets/svg/edit.svg'
import Failed from '../../../assets/svg/failed.svg'
import Loading from '../../../assets/svg/loading.svg'
import ArrowRight from '../../../assets/svg/arrowright.svg'

function Card(props) {
  const [stepStatus, setStepStatus] = useState('')
  const [icon, setIcon] = useState(null)
  
  useEffect(() => {
    if (props.step?.status === 0) {
      setStepStatus('pendingTask')
      setIcon(
        <img className={styles.pencil} src={Edit} alt="Edit pencil icon" />
      )
    } else if (props.step?.status === 1) {
      setStepStatus('verifyingTask')
      setIcon(
        <img className={styles.pencil} src={Loading} alt="Loading icon" />
      )
    } else if (props.step?.status === 2) {
      setStepStatus('completedTask')
      setIcon(<CheckCircleFilled style={{ fontSize: 19, color: 'white' }} />)
    } else if (props.step?.status === 3) {
      setStepStatus('failedTask')
      setIcon(<img className={styles.failed} src={Failed} alt="Error icon" />)
    }
  }, [stepStatus, setStepStatus, props.step])

  let handleStep = () => {
    props.history.push(`/form/${props.formtakementId}/${props.step.stepId}`)
  }
  return (
    <div
      className={styles[stepStatus]}
      onClick={props.step.status !== 1 ? handleStep : handleStep}
    >
      <div className={styles.leftIcon}>{icon}</div>

      <div className={styles.titleContainer}>
        <h2>{props.step.title}</h2>
        {props.step.status === 3 && (
          <div className={styles.errorMessageContainer}>
            <p>{props.step.statusMessage}</p>
            <p className={styles.errorLink}>Opnieuw</p>
          </div>
        )}
        {props.step.status === 1 && (
          <div className={styles.statusMessageContainer}>
            <p>{props.step.statusMessage}</p>
          </div>
        )}
      </div>

      <img
        className={styles.arrowRight}
        src={ArrowRight}
        alt="Arrow right next"
      />
    </div>
  )
}

export default withRouter(Card)
