import React from 'react'
import OnboardingOverviewList from './OverviewList'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './localOnboardingsOverviewPage.module.scss'

import DsOnboardingLogo from '../../assets/svg/Logo.svg'

export class OverviewPage extends React.Component {
  render() {
    let openOnboardings = this.props.forms.filter(f => f.steps.filter(s => s.status != 2).length > 0)
    let finishedOnboardings = this.props.forms.filter(f => f.steps.filter(s => s.status != 2).length === 0)
    return (
      <div>
        <div className={styles.localOverview} style={{textAlign:'center'}}>
          <div className={styles.topContainer}>
            <img src={DsOnboardingLogo} className={styles.dsLogo} />
          </div>
          <div style={{marginTop:'10px', textAlign:'center'}}>
            <p>Welkom in Victus, de tool gemaakt op je het afnemen van formulieren vlekkeloos te laten verlopen. 
              Op deze pagina vind je een overzicht van alle onboardings waar je op dit moment mee bezig bent.</p>
          </div>
          <h2 style={{marginTop:'10px'}}>Je huidige formulieren</h2>
          {openOnboardings.length > 0 ? <div>
            
          <OnboardingOverviewList items={openOnboardings}/>
          </div> : <div>
            <p>Je hebt vanaf dit apparaat nog niet eerder een formulier afgenomen, of je hebt een nieuw account aangemaakt.</p>
            <p>Gebruik de link die aangeleverd is om zelf een nieuw formulier af te nemen! </p>
            </div>}


          {finishedOnboardings.length>0 && <div>
            <h2>Je afgeronde formulieren</h2>
            <OnboardingOverviewList items={finishedOnboardings}/>
          </div>}
          
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { forms: state.form.forms }
}
const ConnectedOverviewPage = connect(mapStateToProps)(OverviewPage)

export default ConnectedOverviewPage
