import React from 'react'

import SummaryPage from '../../summary/summaryPage'
import FileUploadPage from '../../fileUpload/fileUploadPage'
import { Redirect } from 'react-router-dom'
import { submitStep } from '../../../../store/form/form.actions'
import { connect } from 'react-redux'

export class Page extends React.Component {
  render() {    
    switch (this.props.stepNr) {
      case 1:
        return <SummaryPage step={this.props.step} stepNr={this.props.stepNr}  />
      case 2:    
        return (
          <FileUploadPage step={this.props.step} stepNr={this.props.stepNr}  />
        )
      case 3:
        if(!this.props.form || !this.props.formData || Object.keys(this.props.formData).length === 0){
          return <div>loading data...</div>
        }
        this.props.submitStep(this.props.step.stepId)
        if(this.props.stepSubmitSuccess === null)
          return <div>Submitting step...</div>
        else
          return <Redirect to={`/form/step/completed/${this.props.form._id}/${this.props.step.stepId}`}/>
      default:
        return <div>Unknown page?</div>

    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitStep: stepId => dispatch(submitStep(stepId))
  }
}

const mapStateToProps = state => {
  return {
    stepSubmitSuccess: state.form.stepSubmitSuccess,
    form: state.form.selectedForm,
    formData: state.form.selectedFormData
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(Page)

export default ConnectedPage