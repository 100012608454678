import { takeEvery, call, put } from 'redux-saga/effects'

import CONFIG from '../../config'
import { tokenRetrieved, userinfoRetrieved, retrieveUserinfo, exchangeCodeForUserInfo } from './authentication.actions'
import { showError } from '../error/error.actions'

export default function* watcherSaga() {
  yield takeEvery(exchangeCodeForUserInfo.type, authTokenWorkerSaga)
  yield takeEvery(retrieveUserinfo.type, userinfoWorkerSaga)
}

function* authTokenWorkerSaga(action) {
  try {
    const payload = yield call(retrieveAuthToken, action.payload)
    yield put(tokenRetrieved(payload))
    yield put(retrieveUserinfo())
  } catch (e) {
    yield put(showError(e))
  }
}

function* userinfoWorkerSaga(action) {
  try {
    const payload = yield call(retrieveUserInfoRequest, action.payload)
    yield put(userinfoRetrieved(payload))
  } catch (e) {
    yield put(showError(e))
  }
}

function retrieveUserInfoRequest(request) {
  var headers = new Headers()
  headers.append('Authorization', 'Bearer ' + request.accessToken)

  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers,
  }

  return fetch(CONFIG.backend.base + '/userinfo', requestOptions)
    .then(r => {
      return r.json()
    })
    .catch(error =>
      console.error(
        'An error occured while contacting the userinfo endpoint',
        error
      )
    )
}

function retrieveAuthToken(code) {
  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  }

  let search = new URLSearchParams()
  search.append('redirectUrl', CONFIG.frontend.origin + '/auth/redirect')
  search.append('code', code)

  return fetch(
    CONFIG.backend.base + '/token?' + search.toString(),
    requestOptions
  )
    .then(r => {
      return r.json()
    })
    .catch(error =>
      console.error(
        'An error occured while trying to contact the token endpoint',
        error
      )
    )
}
