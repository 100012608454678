import React from 'react'
import styles from './bigLookupPage.module.scss'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Redirect, withRouter } from 'react-router-dom'

import Footer from '../../../../partials/footer'
import MainContainer from '../../../../partials/container'
import Input from '../../../../partials/input'

import back from '../../../../assets/svg/back.svg'
import { connect } from 'react-redux'
import { formDataAdded, requestBigStatus } from '../../../../store/form'
import { KNOWN_DATA } from '../../../../store/userinfo/userinfo.constants'
import ReactLinkify from 'react-linkify'

import Privacy from '../../../../partials/privacy'

export class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      [KNOWN_DATA.BIG_REGISTRATION_NUMBER]: null,
      isVerifying: false,
      toNextStep: false,
      goBack: false,
      goHome: false,
      isPrivacyVisible: false,

    }
  }

  handleInput = value => {
    this.setState({
      [KNOWN_DATA.BIG_REGISTRATION_NUMBER]: value,
    })
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }

  handleBackButton = () => {
    this.setState({
      goHome: true
    })
  }

  handlePrimaryButton = () => {
    this.setState({
      isVerifying: true
    })
    this.props.formDataAdded({[KNOWN_DATA.BIG_REGISTRATION_NUMBER]: this.state[KNOWN_DATA.BIG_REGISTRATION_NUMBER]})
    setTimeout(() => {
      this.setState({
        toNextStep: true,
      })
    }, 5000)
  }
  
  
  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }


  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 64, color: 'black' }} spin />
    )

    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = <img src={back} alt="Go back arrow icon" onClick={this.handleBackButton}/>

    let privacy = <Privacy />

    let title = (
      <div className={styles.titleContainer}>
        <h1>{this.props.step.title}</h1>
        <ReactLinkify>
          {!this.state.isVerifying 
            ? this.props.step.schema.description
            : 'Een moment terwijl we het BIG registratie nummer verifieren.'}
        </ReactLinkify>
      </div>
    )

    let body = (
      <div className={styles.bodyContainer}>
        {!this.state.isVerifying ? (
          <React.Fragment>
            <Input
              label="Big registratie nummer"
              handleInput={this.handleInput}
              placeholder='Vul hier je big registratie nummer in..'
              defaultValue = {this.props.formData[KNOWN_DATA.BIG_REGISTRATION_NUMBER]}
              value = {this.state[KNOWN_DATA.BIG_REGISTRATION_NUMBER]}
            />
            {
              (this.props.bigCheckStatusCode)? <div className={
              this.props.bigCheckStatusCode === 'error'? styles.warningMessage : (this.props.bigCheckStatusCode === 'success') ? styles.successMessage:""}>{this.props.bigCheckStatusMessage}</div>: ""
            }
          </React.Fragment>
        ) : (
          <div className={styles.spinner}>
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
    )

    let footer = (
      <Footer
        primaryButtonText="Verifiëren"
        primaryButtonAction={this.handlePrimaryButton}
        primaryButtonDisabled={!this.state[KNOWN_DATA.BIG_REGISTRATION_NUMBER]}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )

    return (
      <MainContainer
        optionTopLeft={!this.state.isVerifying && goBack}
        optionTopRight={!this.state.isVerifying && privacy}
        title={title}
        body={body}
        footer={!this.state.isVerifying && footer}
        center
      />
    )
  }
  componentDidMount(){
    let bigFromFormData = this.props.formData[KNOWN_DATA.BIG_REGISTRATION_NUMBER]
    this.setState({[KNOWN_DATA.BIG_REGISTRATION_NUMBER]: bigFromFormData})
    if(!bigFromFormData){
      this.props.requestBigStatus()
    }
  }
  componentDidUpdate(prevProps){
    let bigPrev = prevProps.formData[KNOWN_DATA.BIG_REGISTRATION_NUMBER]
    let bigProps = this.props.formData[KNOWN_DATA.BIG_REGISTRATION_NUMBER]
    if(bigPrev!== bigProps){
      this.setState({[KNOWN_DATA.BIG_REGISTRATION_NUMBER]: bigProps})
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
    requestBigStatus: () => dispatch(requestBigStatus())
  }
}

function mapStateToProps(state) {
  return {
    formData: state.form.selectedFormData,
    bigCheckStatusCode: state.form.bigCheckStatusCode,
    bigCheckStatusMessage: state.form.bigCheckStatusMessage
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(Page)

export default withRouter(ConnectedPage)
