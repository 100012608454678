import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'

import back from '../../../../assets/svg/back.svg'
import support from '../../../../assets/svg/support.svg'

import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import Privacy from '../../../../partials/privacy'

import styles from './contractSignInAppPage.module.scss'

import SignaturePad from 'react-signature-canvas'

export class PassportUploadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyVisible: false,
      toNextStep: false,
      goBack: false,
      goHome: false,
      isCanvasEmpty: true,
    }
    this.sigPad = {}
  }
  handlePrimaryButton = () => {
    this.props.formDataAdded({
      file: this.sigPad.getTrimmedCanvas().toDataURL('img/png'),
    })

    this.setState({
      toNextStep: true,
    })
    return false
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }
  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }
  handleBackButton = () => {
    this.setState({
      goHome: true,
    })
  }

  handleClear = () => {
    this.setState({
      isCanvasEmpty: true,
    })
    this.sigPad.clear()
  }

  handleCanvasInput = e => {
    let isCanvasEmpty = this.sigPad.isEmpty()
    this.setState({
      isCanvasEmpty: isCanvasEmpty,
    })
  }

   render() {
    let { trimmedDataURL } = this.state
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 2}`}
        />
      )
    }
    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )

    let privacy = (
      <React.Fragment>
        <img src={support} alt="Privacy icon" onClick={this.handlePrivacy} />
        <Privacy
          visible={this.state.isPrivacyVisible}
          setVisible={this.handlePrivacy}
        />
      </React.Fragment>
    )
    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>Contract ondertekenen</h1>
          <p>Plaats je handtekening in het kader.</p>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.passportContainer}>
            <div className={styles.nameContainer}>
              <label>Naam</label>
              <div className={styles.inputContainer}>
                {this.props.userName || 'Peter Jan'}
              </div>
            </div>
            <div className={styles.signatureContainer}>
              <label>Handtekening</label>

              <SignaturePad
                penColor="black"
                ref={ref => {
                  this.sigPad = ref
                }}
                canvasProps={{
                  className: styles.canvasContainer,
                }}
                onEnd={this.handleCanvasInput}
              />
            </div>
            <div className={styles.passportOptions}>
              <p onClick={this.handleClear}>Opnieuw proberen</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonAction={this.handlePrimaryButton}
        primaryButtonText="Opslaan"
        primaryButtonDisabled={this.state.isCanvasEmpty}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
     return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={!this.state.isVerifying && footer}
        center
      />
     )
   }
 }

const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
    userName: state.userinfo ? state.userinfo.fullname : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassportUploadPage)

export default withRouter(ConnectedPage)