export const steps = [
  {
    title: 'Inloggen',
    description:
      'Meld je moeiteloos aan via IDIN stap. Extra beveiliging door jouw persoonlijke.',
  },
  {
    title: 'Zie wat er nodig is',
    description:
      'Je krijgt een overzichtelijk lijstje van alle administratie die je moet aanleveren.',
  },
  {
    title: 'Start met uploaden',
    description:
      'Upload wat je al hebt en kom later terug om het aan te vullen. Maak alle administratie compleet op jouw tempo.',
  },
  {
    title: 'Krijg status updates',
    description: 'Als er extra informatie nodig is, krijg je een melding.',
  },
  {
    title: 'Alles veilig bij elkaar',
    description:
      'Uiteindelijk heb je al je administratieve documenten veilig bij elkaar en makkelijk toegankelijk voor hergebruik.',
  },
]
