import React from 'react'
import styles from './kvkLookupPage.module.scss'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Redirect, withRouter } from 'react-router-dom'

import Footer from '../../../../partials/footer'
import MainContainer from '../../../../partials/container'
import Input from '../../../../partials/input'

import back from '../../../../assets/svg/back.svg'
import Privacy from '../../../../partials/privacy'
import ReactLinkify from 'react-linkify'

export class KvkLookupPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bigRegistratieNumber: null,
      isVerifying: false,
      toNextStep: false,
      goBack: false,
      goHome: false,
      isPrivacyVisible: false,
    }
  }

  handleInput = value => {
    this.setState({
      bigRegistratieNumber: value,
    })
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }

  handleBackButton = () => {
    this.setState({
      goHome: true
    })
  }

  handlePrimaryButton = () => {
    this.setState({
      isVerifying: true
    })

    setTimeout(() => {
      this.setState({
        toNextStep: true,
      })
    }, 5000)
  }

  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }


  render() {
    const antIcon = (
      <LoadingOutlined style={{ fontSize: 64, color: 'black' }} spin />
    )

    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = <img src={back} alt="Go back arrow icon" onClick={this.handleBackButton}/>

    let privacy = <Privacy />

    let title = (
      <div className={styles.titleContainer}>
        <h1>{this.props.step.title}</h1>
        <ReactLinkify>
          {!this.state.isVerifying 
            ? this.props.step.schema.description
            : 'Een moment terwijl we het Kamer van Koophandel nummer verifieren.'}
        </ReactLinkify>
      </div>
    )

    let body = (
      <div className={styles.bodyContainer}>
        {!this.state.isVerifying ? (
          <React.Fragment>
            <Input
              label="KvK nummer"
              placeholder='Vul hier je KvK nummer in...'
              handleInput={this.handleInput}
            />
          </React.Fragment>
        ) : (
          <div className={styles.spinner}>
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
    )

    let footer = (
      <Footer
        primaryButtonText="Verifiëren"
        primaryButtonAction={this.handlePrimaryButton}
        primaryButtonDisabled={!this.state.bigRegistratieNumber}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )

    return (
      <MainContainer
        optionTopLeft={!this.state.isVerifying && goBack}
        optionTopRight={!this.state.isVerifying && privacy}
        title={title}
        body={body}
        footer={!this.state.isVerifying && footer}
        center
      />
    )
  }
}
export default withRouter(KvkLookupPage)
