import merge from "lodash/merge";

const CONFIG = {
    backend: {
        host: 'dynamic.dimml.io',
        apiHash: '9805a120169dc08d9e92c353dceb4345beec468e',
        apiVersion: 'v0.1'
    },
    frontend: {
        origin: window.location.origin
    }
};
const WINDOW_CONFIG = window['VICTUS_CONFIG'];

function defaultBackendBase() {
    const backend = CONFIG.backend;
    const apiHash = backend.apiHash?'/_/'+backend.apiHash:'';
    const apiVersion = backend.apiVersion?'/'+backend.apiVersion:'';

    return `https://${backend.host}${apiHash}${apiVersion}`
}

if (typeof WINDOW_CONFIG === 'object') merge(CONFIG, WINDOW_CONFIG);
CONFIG.backend.base = CONFIG.backend.base || defaultBackendBase();

export default CONFIG;