import React from 'react'
import { connect } from 'react-redux'
import BankSelect from '../bankSelectPage'
import LoginPage from '../loginPage'

class AuthSwitchPage extends React.Component {
  render() {
    if (this.props.accountExists === null) {
      return <p>Loading page...</p>
    } else if (!this.props.accountExists) {
      return <BankSelect></BankSelect>
    } else {
      return <LoginPage></LoginPage>
    }
  }
}

const mapStateToProps = state => {
  return {
    accountExists: state.auth.accountExists,
  }
}

const ConnectedAuthPage = connect(mapStateToProps, null)(AuthSwitchPage)

export default ConnectedAuthPage
