import { TABLES, MODES } from './victusStorage.constants'

var CryptoJS = require('crypto-js')
const dbVersion = 21
let _db
export const victusVersion = 'victus-1.0'

export function getPincode(state) {
  return state.auth.pincode
}

export function accessStorageAsync(encryptionKey, dispatch){
  return new Promise((resolve) => {
    accessStorage(encryptionKey,dispatch, db=>{
      resolve(db)
      //TODO: reject case
    })
  }) 
}

export function accessStorage(encryptionKey, dispatch, callback = db => {}) {
  try{
    createDBConnection(db => {
      let request = db
        .transaction([TABLES.METADATA], MODES.RW)
        .objectStore(TABLES.METADATA)
        .get('version')

      request.onsuccess = async e => {
        let versionKey = e.target.result
        let versionFromStore = await decrypt(versionKey, encryptionKey)
        if (versionFromStore !== victusVersion) {
          console.error(
            'pincode is not valid!',
            versionFromStore,
            'is not',
            victusVersion
          )
        }
        callback(db)
      }
    })        
  } catch (ex) {
    console.error(
      'STORE: Something went whrong when trying to store the data',
      ex
    )
  }
}

const getEncryptionKey = (encryptionKey) => CryptoJS.SHA256(encryptionKey,'hash' + victusVersion).toString()

export function createDBConnection(dbLoaded) {
  if (_db) {
    dbLoaded(_db)
    return
  }
  let dbPromise = (
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB
  ).open('victus', dbVersion)
  dbPromise.onerror = e => {
    console.error('db Error', e, 'errorcode: ' + dbPromise.errorCode)
  }
  dbPromise.onupgradeneeded = e => {
    console.debug("STORE: resetting store")
    let db = e.target.result

    if (db.objectStoreNames.contains(TABLES.FORMTAKEMENTS))
      db.deleteObjectStore(TABLES.FORMTAKEMENTS)
    if (db.objectStoreNames.contains(TABLES.USERDATA))
      db.deleteObjectStore(TABLES.USERDATA)
    if (db.objectStoreNames.contains(TABLES.METADATA))
      db.deleteObjectStore(TABLES.METADATA)
    if (db.objectStoreNames.contains(TABLES.FORM_DATA))
      db.deleteObjectStore(TABLES.FORM_DATA)

    db.createObjectStore(TABLES.FORMTAKEMENTS, { keyPath: '_id' })
    db.createObjectStore(TABLES.FORM_DATA, {keyPath: '_id'})
    db.createObjectStore(TABLES.USERDATA, { keyPath: '_id' })
    db.createObjectStore(TABLES.METADATA, { keyPath: '_id' })
  }
  dbPromise.onsuccess = e => {
    _db = e.target.result
    dbLoaded(_db)
  }
}

export function encrypt(key, data, encryptionKey) {
  return new Promise(async (resolve,reject) => {
    if (!data){ 
      reject('data was not supplied')
      return
    }

    let type = 'object'
    if(data instanceof File) type = 'file'
    if(data instanceof Array && data.length > 0 && data[0] instanceof File){
      type = 'files'
    }
    else if(typeof data === 'string' || data instanceof String) type = 'string'
    else if(!isNaN(data)) type = 'number'

    switch (type) {
      case 'object':
        complete(JSON.stringify(data))
        break
      case 'string':
        complete(data)
        break
      case 'file':
        let fr = new FileReader()
        fr.onload = (function(file) {
          return function(e) {
            complete(JSON.stringify({
              name: file.name,
              type: file.type,
              data: e.target.result}))
          }
        })(data)
        fr.readAsDataURL(data)
        break
      case 'files':
        let files = []
        for(let file of data){
          let success = true
          let fileData = await new Promise((resolve,reject) => {
            let fr = new FileReader()
            fr.onload = (function(file) {
              return function(e) {
                resolve({
                  name: file.name,
                  type: file.type,
                  data: e.target.result})
              }
            })(file)
            fr.onerror = error => reject(error)
            fr.readAsDataURL(file)
          }).catch((error) => {
            console.error('An error has ocurred while trying to encrypt file', error)
            success = false
          })
          if(success) files.push(fileData)
        }
        complete(JSON.stringify(files))
        break
      case 'number':
        complete(data)
        break
      default:
        complete(data)
        break
    }
    
    function complete(dataObj){
      let encKey = getEncryptionKey(encryptionKey)
      let encryptedData =  CryptoJS.AES.encrypt(dataObj, encKey).toString()
      let encryped = {
        _id: key,
        type: type,
        data: encryptedData,
      }
      resolve(encryped)
    }
  })
}
function dataURItoFile(dataURI, filename) {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
  }

  var file = new File([ab], filename, {type: mimeString})
  return file
}

export function decrypt(data, encryptionKey) {
  return new Promise((resolve,reject) => {
    if (!data) return reject('data was not supplied')
    
    let encryptedData = data.data
    let decryptedData = CryptoJS.AES.decrypt(encryptedData, getEncryptionKey(encryptionKey))
    let formatedDecrypt = decryptedData.toString(CryptoJS.enc.Utf8)

    switch (data.type) {
      case 'object':
        formatedDecrypt = JSON.parse(formatedDecrypt)
        break
      case 'number':
        formatedDecrypt = Number.parseInt(formatedDecrypt)
        break
      case 'string':
        break
      case 'file':
        formatedDecrypt = JSON.parse(formatedDecrypt)
        formatedDecrypt = dataURItoFile(formatedDecrypt.data, formatedDecrypt.name)
        break
      case 'files':
        formatedDecrypt = JSON.parse(formatedDecrypt).map(f => dataURItoFile(f.data,f.name))
        break
      default:
        break
    }

    resolve(formatedDecrypt)
  })
}
