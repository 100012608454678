import { USERINFO } from '../action-types'
import { createAction } from '@reduxjs/toolkit'

export const storeData = createAction(USERINFO.STORE, dataMap => {
  return {
    payload: dataMap,
  }
})
export const persistData = createAction(USERINFO.PERSIST_TO_LOCALSTORAGE, data => {
  return {
    payload: data,
  }
})

export const storeInRedux = createAction(USERINFO.STORE_IN_REDUX, data => {
  return {
    payload: data,
  }
})