import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './OverviewList.module.scss'

import ArrowRight from '../../../assets/svg/arrowright.svg'

const ConnectedList = ({ items }) => (
  <div className={styles.connectedList}>
    <ul>
      {items.map(el => (
        <li key={el._id}>
          <NavLink to={'/form/' + el._id}>
            <div className={styles.companyCard}>
              <img src={el.companyLogo} className={styles.companyIcon} />
              <p> 
                {el.title} 
              </p>
              <img
                className={styles.arrowRight}
                src={ArrowRight}
                alt="Arrow right next"
              />
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  </div>
)

export default ConnectedList
