import React from 'react'
import styles from './landingPage.module.scss'
import MainContainer from '../../partials/container'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { steps } from './data'
import Privacy from '../../partials/privacy'

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.state = { redirect: null }
    let originalUrl = props.location.state?.originalUrl || '/'
    sessionStorage.setItem('postAuthRedirect', originalUrl)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}></Redirect>
    } else if (this.props.accountExists === null) {
      return <p>Loading page...</p>
    } else if (this.props.accountExists) {
      if (this.props.isLoggedIn) {
        return <Redirect to="/overview"></Redirect>
      }
      return <Redirect to="/auth"></Redirect>
    }

    let redirectToAuth = () => {
      this.setState({ redirect: '/auth' })
    }
    let title = (
      <React.Fragment>
        <h1>Aan de slag</h1>
        <p>
          Wij maken de administratie moeiteloos en overzichtelijk. Zo kan jij
          snel aan de slag!
        </p>{' '}
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        {steps.map((step, index) => {
          return (
            <div className={styles.stepsContainer} key={index}>
              <div className={styles.stepsNumberContainer}>
                <div className={styles.stepsNumber}>{index + 1}</div>
              </div>
              <div className={styles.stepsDescriptionContainer}>
                <div className={styles.stepsTitle}>{step.title}</div>
                <div className={styles.stepsDescription}>
                  {step.description}
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
    let privacy = <Privacy />
    let footer = (
      <React.Fragment>
        <button className="primaryButton" onClick={redirectToAuth}>
          Registeren
        </button>
      </React.Fragment>
    )

    return (
      <MainContainer
        title={title}
        body={body}
        footer={footer}
        optionTopRight={privacy}
      ></MainContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    accountExists: state.auth.accountExists,
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const LandingPage = connect(mapStateToProps, null)(Landing)

export default LandingPage
