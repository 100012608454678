import React from 'react'
import styles from './container.module.scss'

import Logo from '../../assets/svg/Logo.svg'
import { Link } from 'react-router-dom'

function MainContainer(props) {
    return (
        <div className={styles.mainContainer}>
            <div
                className={[
                    !props.center
                        ? styles.headerContainer
                        : styles.headerCentered,
                    props.bottomBorder ? styles.bottomBorder : "",
                ].join(" ")} 
            >
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <div>{props.optionTopLeft}</div>
                        <Link to='/overview'><img src={Logo} alt="Empowered by data streams logo" /></Link>
                        <div>{props.optionTopRight}</div>
                    </div>

          <div className={styles.title}>{props.title}</div>
        </div>
      </div>
      <div className={styles.bodyContainer}>{props.body}</div>
      {props.footer && 
        <div className={styles.footerContainer}>{props.footer}</div>
      }
      
    </div>
  )
}

export default MainContainer
