import {
  registerSuccess, register, userinfoRetrieved, tokenRetrieved
} from './authentication.actions'
import { storeInRedux } from '../userinfo/userinfo.actions'
import { KNOWN_DATA } from '../userinfo/userinfo.constants'
import { showError } from '../error/error.actions'

export function authMiddleware({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      if(action.type === register.type){
        dispatch(registerSuccess(action.payload))
      }
      if(action.type === tokenRetrieved.type){
        const {access_token, refresh_token} = action.payload
        return dispatch(storeInRedux({
          [KNOWN_DATA.SIGNICAT_ACCESS_TOKEN]: access_token,
          [KNOWN_DATA.SIGNICAT_REFRESH_TOKEN]: refresh_token
        }))
      }
      if(action.type === userinfoRetrieved.type){
          //filters the usable valid values and returns them in a standardized format
          const p = action.payload
          let data = {
            [KNOWN_DATA.FULLNAME]: p['name'],
            [KNOWN_DATA.CITY]: p['consumer.city'],
            [KNOWN_DATA.COUNTRY]: p['consumer.country'],
            [KNOWN_DATA.BIRTHDATE]: p['consumer.dateofbirth'],
            [KNOWN_DATA.EMAIL]: p['consumer.email'],
            [KNOWN_DATA.GENDER]: p['consumer.gender'],
            [KNOWN_DATA.HOUSENUMBER]: p['consumer.houseno'],
            [KNOWN_DATA.HOUSENUMBER_SUFFIX]: p['consumer.housenosuf'],
            [KNOWN_DATA.INITIALS]: p['consumer.initials'],
            [KNOWN_DATA.POSTAL_CODE]: p['consumer.postalcode'],
            [KNOWN_DATA.STREET]: p['consumer.street'],

            [KNOWN_DATA.LASTNAME]: p['family_name'],
            [KNOWN_DATA.FIRSTNAME]: p['given_name'],
            [KNOWN_DATA.NICKNAME]: p['given_name'],
            [KNOWN_DATA.LOCALE]: p['locale'],
          }
          return dispatch(storeInRedux(data))
      }
      
      return next(action)
    }
  }
}
