import React from 'react'
import styles from './fileCard.module.scss'

import pdfIcon from '../../assets/svg/pdf.svg'
import pngIcon from '../../assets/svg/png.svg'
import jpgIcon from '../../assets/svg/jpg.svg'
import previewIcon from '../../assets/svg/preview.svg'
import downloadIcon from '../../assets/svg/download.svg'
import closeIcon from '../../assets/svg/close.svg'

function FileCard(props) {
  let cardIcon = null

  if (props.fileExtension === 'application/pdf') {
    cardIcon = pdfIcon
  } else if (props.fileExtension === 'image/png') {
    cardIcon = pngIcon
  } else {
    cardIcon = jpgIcon
  }
  let handleRemove = () => {
    props.handleRemove()
  }
  return (
    <div className={styles.container}>
      <div className={styles.fileCardContainer}>
        <img
          src={cardIcon}
          style={{
            fontSize: 32,
            color: '#E3564B',
          }}
          alt="pdf icon"
        />
        <div className={styles.detailContainer}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.fileSize}>{props.fileSize}</div>
        </div>
        <div className={styles.actionIconContainer}>
          <img
            src={downloadIcon}
            style={{ marginRight: props.type !== 'edit' ? '30px' : 'auto' }}
          />
          {props.type !== 'edit' && <img src={previewIcon} />}
        </div>
      </div>
      {props.type === 'edit' && (
        <div className={styles.cancelIcon} onClick={handleRemove}>
          <img
            src={closeIcon}
            style={{
              fontSize: 26,
              color: 'black',
            }}
            alt="remove document icon"
          />
        </div>
      )}
    </div>
  )
}

export default FileCard
