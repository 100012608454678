import { takeEvery, call, put } from 'redux-saga/effects'
import {
  verifyIdentityDocument,
  verifyIdentityDocumentFailed,
  verifyIdentityDocumentSuccess,
  createIdentityVerificationProcess,
  createIdentityVerificationProcessFailed,
  createIdentityVerificationProcessSuccess,
} from './preset.actions'
import CONFIG from '../../config'
import { KNOWN_DATA } from '../userinfo/userinfo.constants'
import { requestBigStatusByUserinfo, requestBigStatusSuccess, requestBigStatusFailed } from '../form/form.actions'

export default function* watcherSaga() {
  yield takeEvery(verifyIdentityDocument.type, verifyIdentityDocumentSaga)
  yield takeEvery(
    createIdentityVerificationProcess.type,
    createIdentityVerificationProcessSaga
  )
  yield takeEvery(
    requestBigStatusByUserinfo.type,
    requestBigStatusByUserinfoSaga
  )
}

function* requestBigStatusByUserinfoSaga(action) {
  try {
    const {
      bigIsValid,
      bigRegistrationNumber,
      professionalGroupCode,
    } = yield call(searchInBigRegister, action.payload)
    if (!bigIsValid) {
      console.log('big is not valid anymore?')
    }
    yield put(requestBigStatusSuccess(bigIsValid, bigRegistrationNumber))
  } catch (e) {
    yield put(requestBigStatusFailed(e.errorMessage))
  }
}

function searchInBigRegister(payload) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  }
  let query = new URLSearchParams({
    birthdate: payload[KNOWN_DATA.BIRTHDATE],
    lastname: payload[KNOWN_DATA.LASTNAME],
    initials: payload[KNOWN_DATA.INITIALS],
  })
  let gender = payload[KNOWN_DATA.GENDER]
  gender = gender === 1 ? 'V' : gender === 2 ? 'M' : null
  if (gender) query.set('gender', gender)

  return fetch(
    `${CONFIG.backend.base}/bigregister/find?${query.toString()}`,
    requestOptions
  ).then(async response => {
    let result = await response.json()
    switch (response.status) {
      case 400:
        throw result
    }
    return result
  })
}

function* verifyIdentityDocumentSaga(action) {
  try {
    let payload = yield call(verifyIdentityDocumentRequest, action.payload)

    yield put(verifyIdentityDocumentSuccess())
  } catch (e) {
    yield put(verifyIdentityDocumentFailed(e))
  }
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

async function verifyIdentityDocumentRequest({
  dossierId,
  processId,
  frontImage,
  backImage,
  selfie,
}) {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let data = {}
  if (frontImage) data.front = await toBase64(frontImage)
  if (backImage) data.back = await toBase64(backImage)
  if (selfie) data.selfie = await toBase64(selfie)
  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: headers,
    body: JSON.stringify(data),
  }

  let query = new URLSearchParams({
    dossierId,
    processId,
  })

  return fetch(
    `${CONFIG.backend.base}/assure/validateFiles?${query.toString()}`,
    requestOptions
  ).then(async response => {
    let result = await response.json()
    switch (response.status) {
      case 400:
        throw result
    }
    return result
  })
}

function* createIdentityVerificationProcessSaga(action) {
  try {
    const { formId, stepId } = action.payload
    let payload = yield call(
      createIdentityVerificationProcessRequest,
      action.payload
    )
    yield put(createIdentityVerificationProcessSuccess(payload, formId, stepId))
  } catch (e) {
    yield put(createIdentityVerificationProcessFailed(e))
  }
}

function createIdentityVerificationProcessRequest({
  dossierId,
  documentType,
  documentNationality,
  formId,
  stepId,
}) {
  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  }
  let query = new URLSearchParams({
    documentType,
    documentNationality,
    formId,
    stepId,
  })
  if (dossierId) query.set('dossierId', dossierId)

  return fetch(
    `${CONFIG.backend.base}/assure/startPassportValidation?${query.toString()}`,
    requestOptions
  ).then(async response => {
    let result = await response.json()
    switch (response.status) {
      case 400:
        throw result
    }
    return result
  })
}
