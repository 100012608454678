import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { requestFormTakement } from '../../store/form'

import MainContainer from '../../partials/container'
import Footer from '../../partials/footer'
import Menu from '../../partials/menu'
import Percentage from '../../partials/percentage'
import CardGroup from '../../partials/cardGroup'
import Card from '../../partials/cardGroup/card'

import { NumberToWords } from './numberToWords'
import Hamburger from '../../assets/svg/menu.svg'

import styles from './formOverviewSlimPage.module.scss'

export class FormOverviewSlimPageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
    }

    this.completeSteps = []
    this.pendingSteps = []
    console.log('wtf')
  }

  handleMenu = () => {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    })
  }

  handleClick = () => {}

  handlePrimaryButton = () => {
    this.setState({
      toNextStep: true,
    })
  }

  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }
  
  componentDidMount() {
    this.props.requestFormTakement(this.props.match.params.formtakementId)
  }

  render() {
    if (!this.props.form) return <div>loading...</div>
    
    if (this.state.toNextStep === true) {
      if (this.pendingSteps.length === 0) {
        return (
          <Redirect
            to={`/form/${this.props.match.params.formtakementId}/complete`}
          />
        )
      } else
        return (
          <Redirect
            to={`/form/${this.props.match.params.formtakementId}/${this.pendingSteps[0].stepId}`}
          />
        )
    }

    if (this.state.goBack === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    this.lastCompletedStep = this.props.form.steps.filter(
      s => s.stepId === this.props.match.params.stepId
    )[0]
    this.pendingSteps = this.props.form.steps
      .slice(this.props.form.steps.indexOf(this.lastCompletedStep))
      .filter(
        step =>
          step.status !== 2 &&
          step.status !== 1 &&
          step.groupId === this.lastCompletedStep.groupId
      )

    if (this.pendingSteps.length === 0) {
      this.pendingSteps = this.props.form.steps.filter(
        step =>
          step.status !== 2 &&
          step.status !== 1 &&
          step.groupId === this.lastCompletedStep.groupId
      )

      if (this.pendingSteps.length === 0) {
        this.pendingSteps = this.props.form.steps.filter(
          step => step.status !== 2 && step.status !== 1
        )
      }
      console.log('steps are:',this.pendingSteps)
    }

    this.props.form.steps.forEach(step => {
      if (step.stepId === this.props.match.params.stepId) {
        this.lastCompletedStep = step
      }

      if (step.status === 2) {
        this.completeSteps.push(step)
      } else {
        this.pendingSteps.push(step)
      }
    })

    this.pendingSteps = this.pendingSteps.slice(0, 3)

    let menu = (
      <React.Fragment>
        <img
          className={styles.menuIcon}
          src={Hamburger}
          alt="menu"
          onClick={this.handleMenu}
        />
        <Menu handleMenu={this.handleMenu} visible={this.state.isMenuVisible} />
      </React.Fragment>
    )

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <Percentage />
          <h1>Het is gelukt! </h1>
          {this.completeSteps.length !== this.props.form.steps.length ? (
            <p>{NumberToWords(this.completeSteps?.length)} down!</p>
          ) : (
            <React.Fragment>
              <h1>Fase is completed!</h1>
              <p
                style={{
                  marginBottom: '10px',
                }}
              >
                Onderstaande gegevens zullen gedeeld worden met Fleks Works
              </p>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          {this.completeSteps.length !== this.props.form.steps.length ? (
            <React.Fragment>
              <div className={styles.completedSteps}>
                {this.lastCompletedStep && (
                  <Card
                    formtakementId={this.props.match.params.formtakementId}
                    step={this.lastCompletedStep}
                  />
                )}
                <p>Volgende stappen</p>
              </div>
              <div className={styles.pendingSteps}>
                <CardGroup
                  steps={this.pendingSteps}
                  formtakementId={this.props.match.params.formtakementId}
                />
              </div>
            </React.Fragment>
          ) : (
            <CardGroup
              steps={this.completeSteps}
              formtakementId={this.props.match.params.formtakementId}
            />
          )}
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonText={
          this.pendingSteps.length !== 0 ? 'Eerst volgende stap' : 'Akkoord'
        }
        primaryButtonAction={this.handlePrimaryButton}
        secondaryButtonText="Overzicht"
        secondaryButtonAction={this.handleSecondaryButton}
      />
    )
    return (
      <MainContainer
        title={title}
        body={body}
        footer={footer}
        optionTopLeft={null}
        optionTopRight={menu}
        center
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestFormTakement: formtakementId =>
      dispatch(requestFormTakement(formtakementId)),
  }
}
const mapStateToProps = state => {
  return {
    form: state.form.selectedForm,
  }
}
const FormOverviewSlimPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormOverviewSlimPageComponent)
export default withRouter(FormOverviewSlimPage)
