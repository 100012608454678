import React from 'react'
import styles from './attachment.module.scss'

import upload from '../../assets/svg/upload.svg'
import camera from '../../assets/svg/camera.svg'
import signature from '../../assets/svg/autograph.svg'
import arrowright from '../../assets/svg/arrowright.svg'

function Attachment(props) {
  let icon = null;
  let title = ""
  if(props.type === "upload") {
    icon = upload
    title ="Select bestand"
  } else if(props.type === "camera") {
    icon = camera
    title ="Select bestand"
  } else if(props.type === "signature") {
    icon = signature
    title ="Handtekening"
  }
  return (
    <React.Fragment>
      <div className={styles.attachmentContainer}>
        <div>
          <img
            src={icon}
            alt="upload a file"
          />
          <h2>{title}</h2>
        </div>
        <img src={arrowright} alt="arrow right" />
      </div>
    </React.Fragment>
  )
}

export default Attachment
