import React from 'react'
import { PRESET } from '../../constants/presets'
import { connect } from 'react-redux'
import { requestFormTakement } from '../../store/form/form.actions'
import PassportPreset from '../presets/passport'
import BIGPreset from '../presets/bigregister'
import VogPreset from '../presets/vog'
import KvkPreset from '../presets/kvk/kvkPreset'
import FilePreset from '../presets/file/filePreset'
import SignPreset from '../presets/sign/signPreset'
export class FormTakementStep extends React.Component {
  render() {
    if (!this.props.step || !this.props.formData) {
      return (
        <div>
          <p>De pagina wordt geladen...</p>
        </div>
      )
    }
    return (
      <div className="takement-step-wrap">
        <ConditionalStep
          step={this.props.step}
          stepNr={this.props.match.params.stepNr}
        ></ConditionalStep>
      </div>
    )
  }

  componentDidMount() {
    this.props.requestFormTakement(this.props.match.params.formtakementId)
  }
}

function ConditionalStep(props) {
  let stepNr = isNaN(props.stepNr) ? 1 : parseInt(props.stepNr)

  switch (props.step.presetId) {
    case PRESET.PASSPORT:
      return <PassportPreset step={props.step} stepNr={stepNr} />
    case PRESET.BIG:
      return <BIGPreset step={props.step} stepNr={stepNr} />
    case PRESET.VOG:
      return <VogPreset step={props.step} stepNr={stepNr} />
    case PRESET.KVK:
      return <KvkPreset step={props.step} stepNr={stepNr} />
    case PRESET.FILE_UPLOAD:
      return <FilePreset step={props.step} stepNr={stepNr} />
    case PRESET.SIGN:
      return <SignPreset step={props.step} stepNr={stepNr} />
    default:
      return <div>preset '{props.step.presetId}' not registered in app</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    step: state.form.selectedForm?.steps.filter(
      s => s.stepId === ownProps.match.params.stepId
    )[0],
    formData: state.form.selectedFormData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestFormTakement: formtakementId =>
      dispatch(requestFormTakement(formtakementId)),
  }
}

export const ConnectedStep = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormTakementStep)

export default ConnectedStep
