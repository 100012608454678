import countries from './countries'
export let dropdownOptions = [
  {
    label: 'Soort legitimatiebewijs',
    defaultValue: 'Selecteer het type document',
    showPreview: false,
    values: [
      {
        key: 'identityCard', 
        name: 'ID Kaart'
      },
      {
        key: 'passport', 
        name: 'Paspoort'
      },
      {
        key: 'driversLicense', 
        name: 'Rijbewijs'
      }
    ],
    type: 'documentType',
  },
  {
    showPreview: false,
    label: 'Land van uitgaven',
    defaultValue: 'Selecteer je land',
    values: countries,
    type: 'documentNationality'
  },
]