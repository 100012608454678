import React from 'react'

import { Redirect, withRouter } from 'react-router-dom'
import { Upload, message } from 'antd'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Attachment from '../../../../partials/attachment'
import FileCard from '../../../../partials/fileCard'
import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import back from '../../../../assets/svg/back.svg'
import Privacy from '../../../../partials/privacy'

import styles from './fileUploadPage.module.scss'

export class FileUploadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVerifying: false,
      isAddingExtraFile: false,
      toNextStep: false,
      goBack: false,
      isFileUploaded: false,
      filesInfo: [],
      fileFormats: props.step.schema.formats
        .map(format => '.' + format)
        .join(','),
    }

    console.log(this.state.fileFormats)

    this.handlePrimaryButton = () => {
      this.props.formDataAdded({
        [this.props.step.dataBindings['file'].value]: this.state.filesInfo.map(
          fileInfo => fileInfo.file
        ),
      })
      this.setState({
        toNextStep: true,
      })
    }

    this.handleSecondaryButton = () => {
      this.setState({
        goBack: true,
      })
    }

    this.handleBackButton = () => {
      this.setState({
        goHome: true,
      })
    }

    this.handleBeforeUpload = fileUploaded => {
      if (
        fileUploaded.type !== 'image/png' &&
        fileUploaded.type !== 'image/jpeg' &&
        fileUploaded.type !== 'application/pdf'
      ) {
        message.error('Invalid file type! ')
        this.setState({
          isAddingExtraFile: false,
        })
        return false
      }
      let fr = new FileReader()
      fr.readAsDataURL(fileUploaded)
      fr.onloadend = () => {}
      this.setState({
        isFileUploaded: true,
        filesInfo: [
          ...this.state.filesInfo,
          {
            file: fileUploaded,
            fileName: fileUploaded.name,
            fileSize: (fileUploaded.size / 1000000).toFixed(2),
            fileExtension: fileUploaded.type,
          },
        ],
        isAddingExtraFile: false,
      })
      console.log(fileUploaded)
      return false
    }

    this.handleRemove = index => {
      if (this.state.filesInfo.length === 1) {
        this.setState({
          isFileUploaded: false,
          filesInfo: [],
        })
        return
      } else {
        let updatedFilesInfo = [...this.state.filesInfo]
        updatedFilesInfo.splice(index, 1)
        this.setState({
          filesInfo: updatedFilesInfo,
        })
      }
    }

    this.handleAdditionalFile = () => {
      this.setState({
        isAddingExtraFile: true,
      })
    }
  }

  render() {
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    if (this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let handleVerification = () => {
      this.setState({
        isVerifying: true,
      })
      this.setState({ isVerifying: false })
      this.handlePrimaryButton()
    }

    let goBack = (
      <img
        src={back}
        alt="Go back arrow icon"
        onClick={this.handleBackButton}
      />
    )
    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>{this.props.step.title}</h1>
          <p>{this.props.step?.schema?.description}</p>
          <ul>
            {this.props.step.schema?.requirements?.map((requirement, index) => {
              return <li key={index}>{requirement}</li>
            })}
          </ul>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          {!this.state.isFileUploaded ? (
            <div className={styles.attachment}>
              <Upload
                name="file"
                accept={this.state.fileFormats}
                beforeUpload={this.handleBeforeUpload}
                showUploadList={false}
              >
                <React.Fragment>
                  <Attachment type="upload" />
                </React.Fragment>
              </Upload>
            </div>
          ) : (
            <div className={styles.fileCardContainer}>
              {this.state.filesInfo.map((fileInfo, index) => {
                return (
                  <FileCard
                    fileExtension={fileInfo.fileExtension}
                    title={fileInfo.fileName}
                    type="edit"
                    fileSize={fileInfo.fileSize + ' mb'}
                    key={index}
                    handleRemove={() => this.handleRemove(index)}
                  />
                )
              })}
              {this.props.step.schema.multiple && (
                <React.Fragment>
                  <p onClick={this.handleAdditionalFile}>Meer toevoegen</p>

                  {this.state.isAddingExtraFile && (
                    <div className={styles.attachment}>
                      <Upload
                        name="file"
                        accept={this.state.fileFormats}
                        beforeUpload={this.handleBeforeUpload}
                        showUploadList={false}
                      >
                        <React.Fragment>
                          <Attachment type="upload" />
                        </React.Fragment>
                      </Upload>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonAction={handleVerification}
        primaryButtonText={'Opslaan'}
        primaryButtonDisabled={!this.state.isFileUploaded}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={footer}
        center
      />
    )
  }
}
const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadPage)

export default withRouter(ConnectedPage)
