import React from 'react'
import styles from './menu.module.scss'

import { Drawer } from 'antd'
import { Link } from 'react-router-dom'

function Menu(props) {
  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={props.handleMenu}
      visible={props.visible}
      maskClosable={true}
    >
      <div className={styles.drawerContainer}>
        <Link
          to={{
            pathname: '/overview',
          }}
        >
          Overzicht
        </Link>
        <p>Profiel</p>
        <p>Privacy beheren</p>
        <Link
          to={{
            pathname: '/',
          }}
        >
          Uitloggen
        </Link>
      </div>
    </Drawer>
  )
}

export default Menu
