import React from 'react'

import { Select } from 'antd'
import styles from './dropdown.module.scss'

function Dropdown(props) {
  let handleSelect = value => {
    props.selectCallback(value, props.type)
  }
  return (
    <React.Fragment>
      <label>{props.label}</label>
      <Select
        className={styles.selectContainer}
        defaultValue={props.defaultValue}
        onChange={handleSelect}
        size="large"
        disabled={props.disabled}
        showArrow={props.showArrow}
        showSearch={props.showSearch}
      >
        {props.values &&
          props.values.map(({ key, name }, index) => (
            <Select.Option key={index} value={key}>
              {name}
            </Select.Option>
          ))}
      </Select>
    </React.Fragment>
  )
}
export default Dropdown
