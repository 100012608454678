import React, { useRef } from 'react'
import PinField from 'react-pin-field'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import styles from './pinConfirmPage.module.scss'
import MainContainer from '../../../partials/container'

import { register } from '../../../store/authentication'

function ConfirmPin(props) {
  if (props.isLoggedIn) {
    return <Redirect to="/auth/register/success" />
  }

  function onComplete(value) {
    props.register(value)
  }

  let title = (
    <React.Fragment>
      <h1>Herhaal uw inlog</h1>
      <p>Kies jouw persoonlijke pincode</p>
    </React.Fragment>
  )

  let body = (
    <React.Fragment>
      <div className={styles.bodyContainer}>
        <div className={styles.inputContainer}>
          <PinField
            className={styles.pinInput}
            length={4}
            onComplete={value => onComplete(value)}
            style={{
              borderRadius: '3px',
              borderColor: '#BABABA',
            }}
            type="password"
          />
        </div>
      </div>
    </React.Fragment>
  )

  return <MainContainer title={title} body={body} center></MainContainer>
}

function mapDispatchToProps(dispatch) {
  return {
    register: pincode => dispatch(register(pincode)),
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const ConnectedPincodePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPin)

export default ConnectedPincodePage
