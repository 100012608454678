import { persistData, storeInRedux, storeData } from "./userinfo.actions"
import { KNOWN_DATA } from "./userinfo.constants"

export function userinfoMiddleware({dispatch}) {
  return function (next) {
    return function (action) {
      if(action.type === storeData.type){

        let knownKeys = Object.values(KNOWN_DATA)

        let resultMap = {}
        for(let [key,val] of Object.entries(action.payload)){
          if(knownKeys.includes(key)){
            resultMap[key] = val
          } else{
            resultMap['custom_'+key] = val
          }
        }

        dispatch(persistData(resultMap))
        return dispatch(storeInRedux(resultMap))
      }
      return next(action)
    }
  }
}
