import { FORMTAKEMENT, PRESET } from '../action-types'
import { createAction } from '@reduxjs/toolkit'
import {KNOWN_DATA} from '../userinfo/userinfo.constants'
export const addFormTakement = createAction(FORMTAKEMENT.ADDED, formtakement => {
  return {
    payload: formtakement
  }
})

export const requestFormTakement = createAction(FORMTAKEMENT.REQUEST_BY_TAKEMENT_ID, formId => {
  return {
    payload: {formId}
  }
})

export const loadedFromState = createAction(FORMTAKEMENT.LOADED_FROM_STATE, formtakements => {
  return {
    payload: formtakements
  }
})

export const formRetrieved = createAction(FORMTAKEMENT.REQUEST_FROM_SERVER_SUCCESS, form => {
  return {
    payload: form
  }
})
export const formSelected = createAction(FORMTAKEMENT.SELECTED, form => {
  return {
    payload: form
  }
})

export const requestFromServer = createAction(FORMTAKEMENT.REQUEST_FROM_SERVER, formId => {
  return {
    payload: {
      formId
    }
  }
})

export const retrievedFromServer = createAction(FORMTAKEMENT.REQUEST_FROM_SERVER_SUCCESS, form => {
  return {
    payload: form
  }
})

export const requestFromServerFailed = createAction(FORMTAKEMENT.REQUEST_FROM_SERVER_FAILED, (formId,error) => {
  return {
    payload: {
      formId,
      error
    }
  }
})

export const submitStep = createAction(FORMTAKEMENT.STEP_SUBMITTED, (stepId) => {
  return {
    payload: {
      stepId
    }
  }
})

export const submitStepRequest = createAction(FORMTAKEMENT.STEP_SUBMITTED_REQUEST, (stepId,formId,formData) => {
  return {
    payload: {
      stepId,
      formId,
      formData
    }
  }
})

export const stepStatusUpdated = createAction(FORMTAKEMENT.STEP_STATUS_UPDATED, (formId, stepId, status, statusMessage)=>{
  return {
    payload:{
      formId,
      stepId,
      status,
      statusMessage
    }
  }
})

export const submitStepFailed = createAction(FORMTAKEMENT.STEP_SUBMITTED_FAILED, (stepId, errorMessage) => {
  return {
    payload: {
      stepId: stepId,
      errorMessage: errorMessage
    }
  }
})
export const submitStepSuccess = createAction(FORMTAKEMENT.STEP_SUBMITTED_SUCCESS, (formId, stepId, result) => {
  return {
    payload: {
      formId: formId,
      stepId: stepId,
      result: result
    }
  }
})

export const updateFormStatus = createAction(FORMTAKEMENT.UPDATE_STATUS, (formId,stepId, status, statusMessage) => {
  return {
    payload: {
      formId,stepId,status,statusMessage
    }
  }
})

export const formDataAdded = createAction(FORMTAKEMENT.DATA_ADDED, (dataMap) => {
  return {
    payload: {
      data: dataMap
    }
  }
})

export const formLoadData = createAction(FORMTAKEMENT.LOAD_DATA, (formId) => {
  return {
    payload: {
      formId: formId
    }
  }
})

export const formDataLoaded = createAction(FORMTAKEMENT.DATA_LOADED, (dataMap) => {
  return {
    payload: {
      data: dataMap
    }
  }
})

export const requestBigStatus = createAction(PRESET.BIG.REQUEST_STATUS)

export const requestBigStatusByUserinfo = createAction(PRESET.BIG.REQUEST_STATUS_BY_USERINFO, (initials, lastname, birthdate, gender) => {
  return {
    payload: {
      [KNOWN_DATA.INITIALS]: initials,
      [KNOWN_DATA.BIRTHDATE]: birthdate,
      [KNOWN_DATA.LASTNAME]: lastname,
      [KNOWN_DATA.GENDER]: gender
    }
  }
})
export const requestBigStatusSuccess = createAction(PRESET.BIG.REQUEST_STATUS_SUCCESS, (isValid, bigRegistrationNumber) => {
  return {
    payload: {
      isValid: isValid,
      bigRegistrationNumber: bigRegistrationNumber
    }
  }
})

export const requestBigStatusFailed = createAction(PRESET.BIG.REQUEST_STATUS_FAILED, (errorMessage) => {
  return {
    payload: {
      errorMessage
    }
  }
})
