import { createReducer } from '@reduxjs/toolkit'

import {
  registerSuccess,
  tokenRetrieved,
  loginSuccess,
  loginFailed,
  logout,
  storeAccountExists,
} from './authentication.actions'
const initialState = {
  accessToken: '',
  idToken: '',
  refreshToken: '',

  pincode: '',
  accountExists: null,

  isLoggedIn: false,
  loginErrorMessage: '',
}

const reducer = createReducer(initialState, {
  [loginSuccess]: (state, action) => {
    state.pincode = action.payload
    state.loginErrorMessage=''
    state.isLoggedIn = true
  },
  [loginFailed]: (state, action) => {
    state.isLoggedIn = false
    state.loginErrorMessage = action.payload.errorMessage
  },
  [logout]: (state, action) => {
    state.pincode = ''
    state.isLoggedIn = false
  },
  [registerSuccess]: (state, action) => {
    state.pincode = action.payload
    state.isLoggedIn = true
  },
  [storeAccountExists]: (state, action) => {
    state.accountExists = action.payload
  },
  // [tokenRetrieved]: (state, action) => {
  //   const {access_token, id_token, refresh_token} = action.payload
  //   state.accessToken = access_token
  //   state.idToken = id_token
  //   if (!action.payload.refreshToken) state.refreshToken = refresh_token
  // }
})

export default reducer
