import React from 'react'
import styles from './cardgroup.module.scss'

import Card from './card'

function CardGroup(props) {
  return (
    <div className={styles.cardGroupContainer}>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      {props.steps.map((step, index) => {
        return <Card key={step.stepId || index} step={step}  formtakementId={props.formtakementId} />
      })}
    </div>
  )
}

export default CardGroup
