import React from 'react'

import { connect } from 'react-redux'
import { requestFormTakement } from '../../store/form'
import CompletedPage from '../completedPage'

import { createIdentityVerificationProcess } from '../../store/preset/preset.actions'

export class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const onButtonClick = () => {
      console.log('hi, im clicked')
      this.props.createIdentityVerificationProcess()
    }
    return <CompletedPage />
  }

  componentDidMount() {
    this.props.requestFormTakement()
  }

  componentDidUpdate(oldProps) {
    if (this.props.formData && !oldProps.formData) {
    }
  }
}

const mapStateToProps = state => {
  return {
    forms: state.form.forms,
    selectedForm: state.form.selectedForm,
    formData: state.form.selectedFormData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestFormTakement: () => dispatch(requestFormTakement('theOnboardingId')),
    createIdentityVerificationProcess: () =>
      dispatch(createIdentityVerificationProcess()),
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(Page)

export default ConnectedPage
