import { loadedFromState, addFormTakement, formDataAdded, formSelected, formDataLoaded, updateFormStatus } from './form.actions'
import { getPincode, encrypt, decrypt, accessStorageAsync } from '../localPersistence/storageHelper'
import { TABLES, MODES } from '../localPersistence/victusStorage.constants'
import { loadState } from '../localPersistence/victusStorage.actions'

import { takeEvery, select, call, put } from 'redux-saga/effects'

export default function* watcherSaga() {
  yield takeEvery(loadState.type, loadStateSaga)
  yield takeEvery(addFormTakement.type, addFormTakementSaga)
  yield takeEvery(formDataAdded.type, formDataAddedSaga)
  yield takeEvery(formSelected.type, formSelectedSaga)
  yield takeEvery(updateFormStatus.type, updateFormSaga)
}
function* updateFormSaga(action){
  const state = yield select()
  const db = yield call(accessStorageAsync, getPincode(state), put)
  const {stepId, formId, status, statusMessage} = action.payload
  function logic(){
    return new Promise(async (resolve, reject) => {
      let getRequest = db
      .transaction([TABLES.FORMTAKEMENTS], MODES.RW)
      .objectStore(TABLES.FORMTAKEMENTS)
      .get(formId)
      getRequest.onsuccess = async (e) => {
        let form = await decrypt(e.target.result, getPincode(state))
        let step = form.steps.filter(s=>s.stepId === stepId)[0]
        step.status = status
        step.statusMessage = statusMessage

        let data = await encrypt(form._id, form, getPincode(state))
        let request = db
          .transaction([TABLES.FORMTAKEMENTS], MODES.RW)
          .objectStore(TABLES.FORMTAKEMENTS)
          .put(data)
        request.onerror = e =>{
          console.error('failed to finish put request', e)
          reject(e)
        }
        request.onsuccess = () =>
          console.debug(
            'STORE: succesfully updated formtakement',
            form
          )
          resolve()
      }
    })  
  }  

  try{
    yield call(logic)
  }
  catch(ex){}
}


function* loadStateSaga(action){
  const state = yield select()
  const db = yield call(accessStorageAsync,getPincode(state), put)
  function logic(){
    return new Promise((resolve)=>{
      const transaction = db.transaction([TABLES.FORMTAKEMENTS], MODES.RW)
      let results = []
      transaction
        .objectStore(TABLES.FORMTAKEMENTS)
        .openCursor().onsuccess = async e => {
        let cursor = e.target.result
    
        if (cursor) {
          results.push(
            await decrypt(cursor.value, getPincode(state))
          )
          cursor.continue()
        } else {
          if (results.length > 0) {
            resolve(results)
            console.debug(
              'STORE: loaded formTakements to redux state',
              results
            )
          }
        }
      }
    })  
  }  

  try{
    let results = yield call(logic)
    yield put(loadedFromState(results))
  }
  catch(ex){}
}

function* addFormTakementSaga(action){
  const state = yield select()
  const db = yield call(accessStorageAsync, getPincode(state), put)
  function logic(){
    return new Promise(async (resolve, reject)=>{
      let data = await encrypt(
        action.payload._id,
        action.payload,
        getPincode(state)
      )
      let request = db
        .transaction([TABLES.FORMTAKEMENTS], MODES.RW)
        .objectStore(TABLES.FORMTAKEMENTS)
        .put(data)
      request.onerror = e =>{
        console.error('failed to finish put request', e)
        reject(e)
      }
      request.onsuccess = () =>
        console.debug(
          'STORE: succesfully persisted formtakement',
          action.payload
        )
        resolve()
    })  
  }  

  try{
    yield call(logic)
  }
  catch(ex){}
}

function* formSelectedSaga(action){
  const state = yield select()
  const db = yield call(accessStorageAsync,getPincode(state), put)
  const selectedFormId = action.payload
  function logic(){
    return new Promise((resolve,reject)=>{
      const transaction = db.transaction([TABLES.FORM_DATA], MODES.RW)
      let results = {}
      transaction
        .objectStore(TABLES.FORM_DATA)
        .openCursor().onsuccess = async e => {
        let cursor = e.target.result

        if (cursor) {
          const keySplit = cursor.value._id.split('|')
          if(keySplit.length === 2){ 
            if(selectedFormId === keySplit[0]){
              results[keySplit[1]] = await decrypt(cursor.value, getPincode(state))
            }
          }
          cursor.continue()
        } else {
          resolve(results)
        }
      }
    })  
  }  

  try{
    const result = yield call(logic)
    yield put(formDataLoaded(result))
  }
  catch(ex){}
}

function* formDataAddedSaga(action){
  const state = yield select()
  

  const { data} = action.payload
  const formtakementId = state.form.selectedForm._id
  function logic(){
    return new Promise(async (resolve,reject) => {
      for (const [key, value] of Object.entries(data)) {
        if (!value) continue
        try {
          await new Promise(async(resolve,reject) => {
            let encryptedData = await encrypt(`${formtakementId}|${key}`, value, getPincode(state))
            
            let request = (await accessStorageAsync(getPincode(state), put))
            .transaction([TABLES.FORM_DATA], MODES.RW)
            .objectStore(TABLES.FORM_DATA)
            .put(encryptedData)

            request.onerror = e => {
              console.error('failed to finish put request', e)
              reject(e)
            }
            request.onsuccess = e =>{
              console.debug(
                'STORE: succesfully persisted formdata key \'"' +
                `${formtakementId}|${key}` +
                  '"\''
              )
              resolve()
            }
          })
          
          resolve()
        } catch (ex) {
          console.error(
            `the error ${ex} was throwns while trying to store the userdata ${key}:${value}`
          )
          reject(ex)
        }
      }
    })  
  }  

  try{
    yield call(logic)
  }
  catch(ex){}
}