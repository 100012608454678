import React from 'react'
import SummaryPage from '../../summary/summaryPage'
import SubmitStep from '../../shared/submitStepPage'
import ContractOverviewPage from '../contractOverviewPage'
import ContractSignMethodChoicePage from '../contractSignMethodChoicePage'
import ContractSignUploadPage from '../contractSignUploadPage'
import ContractSignInAppPage from '../contractSignInAppPage'
export class Page extends React.Component {
  render() {
    switch (this.props.stepNr) {
      case 1:
        return <SummaryPage step={this.props.step} stepNr={this.props.stepNr} />
      case 2:
        return (
          <ContractOverviewPage
            step={this.props.step}
            stepNr={this.props.stepNr}
          />
        )
      case 3:
        return (
          <ContractSignMethodChoicePage
            step={this.props.step}
            stepNr={this.props.stepNr}
          />
        )
      case 4:
        return (
          <ContractSignUploadPage
            step={this.props.step}
            stepNr={this.props.stepNr}
          />
        )
      case 5:
        return (
          <ContractSignInAppPage
            step={this.props.step}
            stepNr={this.props.stepNr}
          />
        )
      case 6:
        return <SubmitStep step={this.props.step}></SubmitStep>
      default:
        return <div>Unknown page?</div>
    }
  }
}
export default Page
