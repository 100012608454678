import { getPincode, accessStorage,decrypt,encrypt} from '../localPersistence/storageHelper'
import { storeInRedux, persistData } from './userinfo.actions'
import { TABLES, MODES } from '../localPersistence/victusStorage.constants'
import {loadState, stateLoaded} from '../localPersistence/victusStorage.actions'
export function userinfoStoreMiddleware({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      if (action.type === loadState.type) {
        accessStorage(getPincode(getState()), dispatch, db => {
          const transaction = db.transaction([TABLES.USERDATA], MODES.RW)
          let results = {}
          transaction
            .objectStore(TABLES.USERDATA)
            .openCursor().onsuccess = async e => {
            let cursor = e.target.result

            if (cursor) {
              results[cursor.value._id] = await decrypt(
                cursor.value,
                getPincode(getState())
              )
              cursor.continue()
            } else {
              dispatch(storeInRedux(results))
              dispatch(stateLoaded())
            }
          }
        })
      }
      else if(action.type === persistData.type) {
        accessStorage(getPincode(getState()), dispatch, async db => {  
          let store = db
            .transaction([TABLES.USERDATA], MODES.RW)
            .objectStore(TABLES.USERDATA)
          for (const [key, value] of Object.entries(action.payload)) {
            if (!value) continue
            try {
              let request = store.put(
                await encrypt(key, value, getPincode(getState()))
              )
              request.onerror = e =>
                console.error('failed to finish put request', e)
              request.onsuccess = e =>
                console.debug(
                  'STORE: succesfully persisted userinfo key \'"' +
                    key +
                    '"\''
                )
            } catch (ex) {
              console.error(
                `the error ${ex} was throwns while trying to store the userdata ${key}:${value}`
              )
            }
          }
        })
      }
      return next(action)
    }
  }
}
