import React from 'react'

import { Redirect, withRouter } from 'react-router-dom'

import MainContainer from '../../../../partials/container'
import back from '../../../../assets/svg/back.svg'

import styles from './summaryPage.module.scss'

import Footer from '../../../../partials/footer'
import Privacy from '../../../../partials/privacy'
import ReactLinkify from 'react-linkify'

export class SummaryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyVisible: false,
      toNextStep: false,
      goBack: false,
      goHome: false
    }
  }

  handlePrivacy = () => {
    this.setState({
      isPrivacyVisible: !this.state.isPrivacyVisible,
    })
  }

  handlePrimaryButton = () => {
    this.setState({
      toNextStep: true,
    })
  }

  handleSecondaryButton = () => {
    this.setState({
      goBack: true,
    })
  }
  handleBackButton = () => {
    this.setState({
      goHome: true
    })
  }

  render() {
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true || this.state.goHome === true) {
      return <Redirect to={`/form/${this.props.match.params.formtakementId}`} />
    }

    let goBack = <img src={back} alt="Go back arrow icon" onClick={this.handleBackButton}/>

    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>{this.props.step.title}</h1>
          <ReactLinkify>{this.props.step.schema && this.props.step.schema.description}</ReactLinkify>
          <ul>
            {this.props.step.schema &&
              this.props.step.schema.requirements &&
              this.props.step.schema.requirements.map((requirement, index) => {
                return <li key={index}>{requirement}</li>
              })}
          </ul>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        {this.props.step.schema?.image && (
          <div className={styles.imageContainer}>
            <img
              src={this.props.step.schema && this.props.step.schema.image}
              alt="description"
            />
          </div>
        )}
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonText="Ga verder"
        primaryButtonAction={this.handlePrimaryButton}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        title={title}
        body={body}
        footer={footer}
        optionTopLeft={goBack}
        optionTopRight={privacy}
        center
      />
    )
  }
}
export default withRouter(SummaryPage)
