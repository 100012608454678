import { createReducer } from '@reduxjs/toolkit'
import { storeInRedux } from './userinfo.actions'
import { KNOWN_DATA } from './userinfo.constants'

function getinitialState() {
  const state = {}
  for (let d of Object.values(KNOWN_DATA)) {
    state[d] = ''
  }
  return state
}

const initialState = getinitialState()

const reducer = createReducer(initialState, {
  [storeInRedux]: (state, action) => {
    Object.assign(state, action.payload)
  },
})

export default reducer
