import { addFormTakement, loadedFromState, formDataAdded, formDataLoaded, submitStepSuccess, submitStep, submitStepFailed, updateFormStatus, requestBigStatusSuccess, requestBigStatusFailed} from './form.actions'
import { createReducer } from '@reduxjs/toolkit'
import { formSelected } from './form.actions'
const initialState = {
  forms: [],
  formData:[],
  selectedForm: null,
  // formDataLoaded: false,
  selectedFormData: null,
  stepSubmitSuccess: null,

  bigCheckStatusMessage: null,
  bigCheckStatusCode: null
}

const reducer = createReducer(initialState, {
  [addFormTakement]: (state, action) => {
    let formsFromStore = state.forms.filter(f=>f._id === action.payload._id)
    if(formsFromStore.length>0){
      let formFromStore = formsFromStore[0]
      Object.assign(formFromStore,action.payload)
    }
    else{
      state.forms.push(action.payload)
    }
  },
  [loadedFromState]: (state, action) => {
    state.forms.push(...action.payload)
  },
  [formSelected]: (state, action) => {
    let selected = state.forms.filter(f => f._id === action.payload)
    state.selectedForm = selected[0]
  },
  [formDataAdded]: (state, action) => {
    const {data} = action.payload

    if(state.selectedFormData == null){
      console.error("no form selected")
    }
    Object.assign(state.selectedFormData, data)
  },
  [formDataLoaded]: (state, action) => {
    const {data} = action.payload
    state.selectedFormData = data
  },
  [submitStep]: (state, action) => {
    state.stepSubmitSuccess = null
  },
  [updateFormStatus]: (state, action) => {
    const{status,statusMessage, formId, stepId} = action.payload
    let formInStore = state.forms.filter(f => f._id === formId)[0]
    let step = formInStore.steps.filter(s => s.stepId === stepId)[0]
    step.status = status
    step.statusMessage = statusMessage
    if(formInStore._id === state.selectedForm._id) state.selectedForm = formInStore
  },
  [submitStepSuccess]: (state, action) => {
    state.stepSubmitSuccess = true
  },
  [submitStepFailed]: (state, action) => {
    state.stepSubmitSuccess = false
  },
  [requestBigStatusSuccess]: (state, action) => {
    state.bigCheckStatusMessage = 'We hebben alvast voor jou gezocht naar je bigregistratie nummer, scheelt weer moeite!'
    state.bigCheckStatusCode = 'success'
  },
  // [requestBigStatusFailed]: (state, action) => {
  //   state.bigCheckStatusMessage = `We hebben je BIG registratie niet kunnen vinden. Vul je BIG
  //   registatie nummer in of controleer het nummer zodat we het
  //   kunnen verifieren.`
  //   state.bigCheckStatusCode = 'error'
  // }
})

export default reducer
