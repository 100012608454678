import { AUTH } from '../action-types'
import { createAction } from '@reduxjs/toolkit'

/* OAUTH */
export const exchangeCodeForUserInfo = createAction(AUTH.EXCHANGE_CODE_FOR_USERINFO, authenticationCode => {
  return {
    payload: authenticationCode,
  }
})



export const tokenRetrieved = createAction(AUTH.TOKEN_RETRIEVED, result => {
  return {
    payload: result,
  }
})

export const retrieveUserinfo = createAction(AUTH.RETRIEVE_USERINFO)
export const userinfoRetrieved = createAction(
  AUTH.USERINFO_RETRIEVED,
  result => {
    return {
      payload: result,
    }
  }
)


/* registering */
export const register = createAction(AUTH.REGISTER, pincode => {
  return {
    payload: pincode,
  }
})
export const registerSuccess = createAction(AUTH.REGISTER_SUCCESS, pincode => {
  return {
    payload: pincode,
  }
})
export const checkAccountExists = createAction(AUTH.CHECK_ACCOUNT_EXISTS)

/* login */
export const login = createAction(AUTH.LOGIN, pincode => {
  return {
    payload: pincode,
  }
})
export const loginSuccess = createAction(AUTH.LOGIN_SUCCESS, pincode => {
  return {
    payload: pincode,
  }
})
export const loginFailed = createAction(AUTH.LOGIN_FAILED, errorMessage => {
  return {
    payload: { errorMessage: errorMessage },
  }
})

export const logout = createAction(AUTH.LOGOUT)

export const storeAccountExists = createAction(
  AUTH.STORE_ACCOUNT_EXISTS,
  exists => {
    return {
      payload: exists,
    }
  }
)
