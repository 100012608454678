import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { Upload } from 'antd'

import MainContainer from '../../../../partials/container'
import Footer from '../../../../partials/footer'
import Attachment from '../../../../partials/attachment'
import FileCard from '../../../../partials/fileCard'
import { formDataAdded } from '../../../../store/form'
import { connect } from 'react-redux'

import back from '../../../../assets/svg/back.svg'
import Privacy from '../../../../partials/privacy'

import styles from './contractOverviewPage.module.scss'

export class FileUploadPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVerifying: false,
      toNextStep: false,
      goBack: false,
      isFileUploaded: false,
      fileName: '',
      fileSize: null,
    }

    this.handlePrimaryButton = () => {
      this.setState({
        toNextStep: true,
      })
    }

    this.handleSecondaryButton = () => {
      this.setState({
        goBack: true,
      })
    }

    this.handleBeforeUpload = fileUploaded => {
      let fr = new FileReader()
      fr.readAsDataURL(fileUploaded)
      fr.onloadend = () => {
        //filePreview:  fr.result
        this.props.formDataAdded({
          [this.props.step.dataBindings['file'].value]: fileUploaded,
        })
      }
      this.setState({
        isFileUploaded: true,
        fileName: fileUploaded.name,
        fileSize: (fileUploaded.size / 1000000).toFixed(2),
      })
      return false
    }
  }

  render() {
    if (this.state.toNextStep === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr + 1}`}
        />
      )
    }

    if (this.state.goBack === true) {
      return (
        <Redirect
          to={`/form/${this.props.match.params.formtakementId}/${
            this.props.match.params.stepId
          }/${this.props.stepNr - 1}`}
        />
      )
    }

    let handleVerification = () => {
      this.setState({
        isVerifying: true,
      })

      // setTimeout(() => {
      this.setState({ isVerifying: false })
      this.handlePrimaryButton()
      // }, 3000)
    }

    let goBack = <img src={back} alt="Go back arrow icon" onClick={this.handleBackButton}/>
    let privacy = <Privacy />

    let title = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <h1>{this.props.step.title}</h1>
          <p>{this.props.step?.schema?.description}</p>
          <ul>
            {this.props.step.schema?.requirements?.map((requirement, index) => {
              return <li key={index}>{requirement}</li>
            })}
          </ul>
        </div>
      </React.Fragment>
    )

    let body = (
      <React.Fragment>
        <div className={styles.bodyContainer}>
          <div className={styles.fileCardContainer}>
            <FileCard title="Contract" fileSize="Datastreams" type="view" />
          </div>
        </div>
      </React.Fragment>
    )

    let footer = (
      <Footer
        primaryButtonAction={handleVerification}
        primaryButtonText={'Ondertekenen'}
        secondaryButtonAction={this.handleSecondaryButton}
        secondaryButtonText="Terug"
      />
    )
    return (
      <MainContainer
        optionTopLeft={goBack}
        optionTopRight={privacy}
        title={title}
        body={body}
        footer={footer}
        center
      />
    )
  }
}
const mapStateToProps = state => {
  return {
    formData: state.form?.selectedFormData ? state.form.selectedFormData : null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    formDataAdded: dataMap => dispatch(formDataAdded(dataMap)),
  }
}

export const ConnectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadPage)

export default withRouter(ConnectedPage)
